/* Slider */
.slick-slider {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
	display: block;
	--tw-pan-y: pan-y;
	touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom);
	-webkit-user-select: none;
	   -moz-user-select: none;
	        user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	margin: 0px;
	display: block;
	overflow: hidden;
	padding: 0px;
}

.slick-list:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
}

.slick-list.dragging {
	cursor: grab;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	top: 0px;
	left: 0px;
	display: block;
}

.slick-track::before {
	display: table;
	--tw-content: '';
	content: var(--tw-content);
}

.slick-track::after {
	clear: both;
	display: table;
	--tw-content: '';
	content: var(--tw-content);
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	float: left;
	display: none;
	height: 100%;
	min-height: 1px;
}

[dir='rtl'] .slick-slide {
	float: right;
}

.slick-slide img {
	display: block;
}

.slick-slide.slick-loading img {
	display: none;
}

.slick-slide.dragging img {
	pointer-events: none;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0);
}

.slick-arrow.slick-hidden {
	display: none;
}

.slick-prev,
.slick-next {
	position: absolute;
	bottom: 1rem;
	height: 2rem;
	width: 2rem;
	overflow: hidden;
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(0 0 0 / var(--tw-bg-opacity));
	background-position: center;
	background-repeat: no-repeat;
	color: rgba(0, 0, 0, 0);
}

.slick-prev:hover,
.slick-next:hover {
	--tw-bg-opacity: 1 !important;
	background-color: rgb(4 83 141 / var(--tw-bg-opacity)) !important;
}

.slick-prev:focus,
.slick-next:focus {
	--tw-bg-opacity: 1 !important;
	background-color: rgb(4 83 141 / var(--tw-bg-opacity)) !important;
}

@media (min-width: 768px) {
	.slick-prev,
.slick-next {
		bottom: 34px;
	}
}

.slick-prev {
	left: 0.75rem;
	background-image: url(https://pet.gwp-cdn.elanco.com/_next/static/media/icon-arrow-left-white.58b60546.svg);
}

.slick-next {
	right: 0.75rem;
	background-image: url(https://pet.gwp-cdn.elanco.com/_next/static/media/icon-arrow-right-white.58ce12c6.svg);
}

.dark .slick-prev {
	background-image: url(https://pet.gwp-cdn.elanco.com/_next/static/media/icon-arrow-left-black.5c1f070c.svg);
}

.dark .slick-next {
	background-image: url(https://pet.gwp-cdn.elanco.com/_next/static/media/icon-arrow-right-black.8b932e5c.svg);
}

.slick-dots {
	margin-top: 2rem;
	padding-left: 3.5rem;
	padding-right: 3.5rem;
	padding-bottom: 1rem;
	text-align: center;
}

@media (min-width: 768px) {
	.slick-dots {
		padding-bottom: 2rem;
	}
}

.slick-dots li {
	display: inline-block;
}

.slick-dots li button {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
	display: block;
	height: 1rem;
	width: 1rem;
	overflow: hidden;
	border-radius: 9999px;
	--tw-bg-opacity: 1;
	background-color: rgb(128 146 158 / var(--tw-bg-opacity));
	text-indent: 99999px;
}

.slick-dots li.slick-active button {
	--tw-bg-opacity: 1;
	background-color: rgb(238 232 223 / var(--tw-bg-opacity));
}

.slick-dots li:not(.slick-active) button:focus,
.slick-dots li:not(.slick-active) button:hover {
	--tw-bg-opacity: 1;
	background-color: rgb(2 37 62 / var(--tw-bg-opacity));
}

.slick-slider--dark-mode .slick-prev,
	.slick-slider--dark-mode .slick-next {
	--tw-bg-opacity: 1;
	background-color: rgb(2 37 62 / var(--tw-bg-opacity));
}

.slick-slider--dark-mode .slick-prev:hover,
	.slick-slider--dark-mode .slick-next:hover {
	--tw-bg-opacity: 1;
	background-color: rgb(77 102 120 / var(--tw-bg-opacity));
}

.slick-slider--dark-mode .slick-prev:focus,
	.slick-slider--dark-mode .slick-next:focus {
	--tw-bg-opacity: 1;
	background-color: rgb(77 102 120 / var(--tw-bg-opacity));
}

.slick-slider--dark-mode .slick-dots li:not(.slick-active) button {
	--tw-bg-opacity: 1;
	background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.slick-slider--dark-mode .slick-dots li:not(.slick-active) button:hover {
	--tw-bg-opacity: 1;
	background-color: rgb(188 186 186 / var(--tw-bg-opacity));
}

.slick-slider--dark-mode .slick-dots li:not(.slick-active) button:focus {
	--tw-bg-opacity: 1;
	background-color: rgb(188 186 186 / var(--tw-bg-opacity));
}
/* Styles for custom navigation */
	/* Global styles for slick-slider */
	.card-carousel.custom-nav .slick-slider {
	padding-bottom: 5rem
}
	/* Global styles for slick-arrow */
	.card-carousel.custom-nav .slick-arrow {
	z-index: 11
}
	/* Styles for rangeBar */
	.card-carousel.custom-nav .range-bar {
	position: absolute
}
	.card-carousel.custom-nav .range-bar {
	bottom: -5px
}
	.card-carousel.custom-nav .range-bar {
	z-index: 10
}
	.card-carousel.custom-nav .range-bar {
	margin-left: auto;
	margin-right: auto
}
	.card-carousel.custom-nav .range-bar {
	width: 100%
}
	.card-carousel.custom-nav .range-bar {
	padding-left: 4rem;
	padding-right: 4rem
}
	.card-carousel.custom-nav .range-bar {
	text-align: center
}
	@media (min-width: 768px) {
	.card-carousel.custom-nav .range-bar {
		bottom: 10px
	}
}
	@media (min-width: 768px) {
	.card-carousel.custom-nav .range-bar {
		padding-left: 33%;
		padding-right: 33%
	}
}
	/* Styles for input range element */
	.card-carousel.custom-nav input[type='range'] {
	padding: 0px
}
	@media (min-width: 768px) {
		.card-carousel .slick-dots {
		padding-left: 33%;
		padding-right: 33%
	}

		.card-carousel .slick-prev {
		left: 25%
	}

		.card-carousel .slick-next {
		right: 25%
	}
	}
	.card-carousel .slick-track {
	display: flex
}
	.card-carousel .slick-track {
	justify-content: center
}
.verticalyCenteredArrows .slick-prev,
  .verticalyCenteredArrows .slick-next,
  .verticalyCenteredArrowsBlack .slick-prev,
  .verticalyCenteredArrowsBlack .slick-next,
  .wideVerticalyCenteredArrowsBlack .slick-prev,
  .wideVerticalyCenteredArrowsBlack .slick-next {
    top: 50%;
}
  .verticalyCenteredArrows .slick-prev,
  .verticalyCenteredArrows .slick-next,
  .verticalyCenteredArrowsBlack .slick-prev,
  .verticalyCenteredArrowsBlack .slick-next,
  .wideVerticalyCenteredArrowsBlack .slick-prev,
  .wideVerticalyCenteredArrowsBlack .slick-next {
    z-index: 10;
}
  .verticalyCenteredArrows .slick-prev,
  .verticalyCenteredArrows .slick-next,
  .verticalyCenteredArrowsBlack .slick-prev,
  .verticalyCenteredArrowsBlack .slick-next,
  .wideVerticalyCenteredArrowsBlack .slick-prev,
  .wideVerticalyCenteredArrowsBlack .slick-next {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
  .verticalyCenteredArrows .slick-prev,
  .verticalyCenteredArrows .slick-next,
  .verticalyCenteredArrowsBlack .slick-prev,
  .verticalyCenteredArrowsBlack .slick-next,
  .wideVerticalyCenteredArrowsBlack .slick-prev,
  .wideVerticalyCenteredArrowsBlack .slick-next {
    background-color: rgba(0, 0, 0, 0);
}
  .verticalyCenteredArrows .slick-prev,
  .verticalyCenteredArrows .slick-next,
  .verticalyCenteredArrowsBlack .slick-prev,
  .verticalyCenteredArrowsBlack .slick-next,
  .wideVerticalyCenteredArrowsBlack .slick-prev,
  .wideVerticalyCenteredArrowsBlack .slick-next {
    border-width: 0px;
}
  .verticalyCenteredArrows .slick-prev, .verticalyCenteredArrowsBlack .slick-prev, .wideVerticalyCenteredArrowsBlack .slick-prev {
    background-image: url(https://pet.gwp-cdn.elanco.com/_next/static/media/icon-arrow-left-blue.dd3a9e05.svg);
  }
  .verticalyCenteredArrows .slick-next, .verticalyCenteredArrowsBlack .slick-next, .wideVerticalyCenteredArrowsBlack .slick-next {
    background-image: url(https://pet.gwp-cdn.elanco.com/_next/static/media/icon-arrow-right-blue.83126c6f.svg);
  }
  .verticalyCenteredArrows ul, .verticalyCenteredArrowsBlack ul, .wideVerticalyCenteredArrowsBlack ul {
    position: absolute;
}
  .verticalyCenteredArrows ul, .verticalyCenteredArrowsBlack ul, .wideVerticalyCenteredArrowsBlack ul {
    left: 0px;
    right: 0px;
}
  .verticalyCenteredArrows ul, .verticalyCenteredArrowsBlack ul, .wideVerticalyCenteredArrowsBlack ul {
    bottom: -10px;
}

.verticalyCenteredArrowsBlack .slick-list, .wideVerticalyCenteredArrowsBlack .slick-list {
    margin-left: auto;
    margin-right: auto;
}

.verticalyCenteredArrowsBlack .slick-list, .wideVerticalyCenteredArrowsBlack .slick-list {
    max-width: 768px;
}

.verticalyCenteredArrowsBlack .slick-prev,
	.verticalyCenteredArrowsBlack .slick-next,
	.wideVerticalyCenteredArrowsBlack .slick-prev,
	.wideVerticalyCenteredArrowsBlack .slick-next {
    height: 2rem;
}

.verticalyCenteredArrowsBlack .slick-prev,
	.verticalyCenteredArrowsBlack .slick-next,
	.wideVerticalyCenteredArrowsBlack .slick-prev,
	.wideVerticalyCenteredArrowsBlack .slick-next {
    width: 2rem;
}

.verticalyCenteredArrowsBlack .slick-prev,
	.verticalyCenteredArrowsBlack .slick-next,
	.wideVerticalyCenteredArrowsBlack .slick-prev,
	.wideVerticalyCenteredArrowsBlack .slick-next {
    border-radius: 9999px;
}

.verticalyCenteredArrowsBlack .slick-prev,
	.verticalyCenteredArrowsBlack .slick-next,
	.wideVerticalyCenteredArrowsBlack .slick-prev,
	.wideVerticalyCenteredArrowsBlack .slick-next {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.verticalyCenteredArrowsBlack .slick-disabled, .wideVerticalyCenteredArrowsBlack .slick-disabled {
    cursor: not-allowed;
}

.verticalyCenteredArrowsBlack .slick-disabled, .wideVerticalyCenteredArrowsBlack .slick-disabled {
    opacity: 0.2;
}

.verticalyCenteredArrowsBlack .slick-prev, .wideVerticalyCenteredArrowsBlack .slick-prev {
    left: 0px;
}

.verticalyCenteredArrowsBlack .slick-prev, .wideVerticalyCenteredArrowsBlack .slick-prev {
		background-image: url(https://pet.gwp-cdn.elanco.com/_next/static/media/icon-arrow-left-white.58b60546.svg);
	}

.verticalyCenteredArrowsBlack .slick-next, .wideVerticalyCenteredArrowsBlack .slick-next {
    right: 0px;
}

.verticalyCenteredArrowsBlack .slick-next, .wideVerticalyCenteredArrowsBlack .slick-next {
		background-image: url(https://pet.gwp-cdn.elanco.com/_next/static/media/icon-arrow-right-white.58ce12c6.svg);
	}

.verticalyCenteredArrowsBlack .slick-prev,
	.verticalyCenteredArrowsBlack .slick-next,
	.wideVerticalyCenteredArrowsBlack .slick-prev,
	.wideVerticalyCenteredArrowsBlack .slick-next {
    border-width: 0px;
}

@media (min-width: 768px) {
    .verticalyCenteredArrowsBlack .slick-prev,
	.verticalyCenteredArrowsBlack .slick-next,
	.wideVerticalyCenteredArrowsBlack .slick-prev,
	.wideVerticalyCenteredArrowsBlack .slick-next {
        top: auto;
    }
}

@media (min-width: 768px) {
    .verticalyCenteredArrowsBlack .slick-prev,
	.verticalyCenteredArrowsBlack .slick-next,
	.wideVerticalyCenteredArrowsBlack .slick-prev,
	.wideVerticalyCenteredArrowsBlack .slick-next {
        bottom: -100px;
    }
}

.verticalyCenteredArrowsBlack .slick-dots li button, .wideVerticalyCenteredArrowsBlack .slick-dots li button {
    height: 0.625rem;
}

.verticalyCenteredArrowsBlack .slick-dots li button, .wideVerticalyCenteredArrowsBlack .slick-dots li button {
    width: 0.625rem;
}

.verticalyCenteredArrowsBlack .slick-dots li button, .wideVerticalyCenteredArrowsBlack .slick-dots li button {
    --tw-bg-opacity: 1;
    background-color: rgb(238 232 223 / var(--tw-bg-opacity));
}

.verticalyCenteredArrowsBlack .slick-dots li.slick-active button, .wideVerticalyCenteredArrowsBlack .slick-dots li.slick-active button {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.wideVerticalyCenteredArrowsBlack {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
}

.wideVerticalyCenteredArrowsBlack .slick-list {
    max-width: 100%;
}

.wideVerticalyCenteredArrowsBlack .slick-prev {
    left: -3rem;
}

@media (min-width: 1536px) {
    .wideVerticalyCenteredArrowsBlack .slick-prev {
        left: 0.75rem;
    }
}

.wideVerticalyCenteredArrowsBlack .slick-next {
    right: -3rem;
}

@media (min-width: 1536px) {
    .wideVerticalyCenteredArrowsBlack .slick-next {
        right: 0.75rem;
    }
}

.hasHrBelow .slick-dots {
    bottom: -100px;
}

@media (min-width: 768px) {
    .hasHrBelow .slick-dots {
        bottom: -120px;
    }
}

.wideVerticalyCenteredArrowsBlack {
    margin-bottom: 65px;
}

.wideVerticalyCenteredArrowsBlack .slick-dots {
    bottom: -65px;
}
picture[style] {
    display: block !important;
}
.sr-only{
	position:
		absolute;
	width:
		1px;
	height:
		1px;
	padding:
		0;
	margin:
		-1px;
	overflow:
		hidden;
	clip:
		rect(0, 0, 0, 0);
	white-space:
		nowrap;
	border-width:
		0;
}
.pointer-events-none{
	pointer-events:
		none;
}
.visible{
	visibility:
		visible;
}
.invisible{
	visibility:
		hidden;
}
.static{
	position:
		static;
}
.\!fixed{
	position:
		fixed !important;
}
.fixed{
	position:
		fixed;
}
.absolute{
	position:
		absolute;
}
.relative{
	position:
		relative;
}
.sticky{
	position:
		sticky;
}
.inset-0{
	inset:
		0px;
}
.inset-x-0{
	left:
		0px;
	right:
		0px;
}
.inset-y-0{
	top:
		0px;
	bottom:
		0px;
}
.\!bottom-0{
	bottom:
		0px !important;
}
.\!right-0{
	right:
		0px !important;
}
.\!top-\[14px\]{
	top:
		14px !important;
}
.-bottom-28{
	bottom:
		-7rem;
}
.-left-12{
	left:
		-3rem;
}
.-left-16{
	left:
		-4rem;
}
.-left-20{
	left:
		-5rem;
}
.-left-44{
	left:
		-11rem;
}
.-left-56{
	left:
		-14rem;
}
.-left-6{
	left:
		-1.5rem;
}
.-right-4{
	right:
		-1rem;
}
.-right-6{
	right:
		-1.5rem;
}
.-top-16{
	top:
		-4rem;
}
.-top-6{
	top:
		-1.5rem;
}
.bottom-0{
	bottom:
		0px;
}
.bottom-2{
	bottom:
		0.5rem;
}
.bottom-5{
	bottom:
		1.25rem;
}
.bottom-6{
	bottom:
		1.5rem;
}
.bottom-7{
	bottom:
		1.75rem;
}
.left-0{
	left:
		0px;
}
.left-1\/2{
	left:
		50%;
}
.left-35{
	left:
		9.8rem !important;
}
.left-5{
	left:
		1.25rem;
}
.left-\[-9999px\]{
	left:
		-9999px;
}
.left-\[50\%\]{
	left:
		50%;
}
.left-auto{
	left:
		auto;
}
.right-0{
	right:
		0px;
}
.right-2\.5{
	right:
		0.625rem;
}
.right-3{
	right:
		0.75rem;
}
.right-4{
	right:
		1rem;
}
.top-0{
	top:
		0px;
}
.top-1\/2{
	top:
		50%;
}
.top-16{
	top:
		4rem;
}
.top-2{
	top:
		0.5rem;
}
.top-2\.5{
	top:
		0.625rem;
}
.top-4{
	top:
		1rem;
}
.top-5{
	top:
		1.25rem;
}
.top-8{
	top:
		2rem;
}
.top-\[-10\%\]{
	top:
		-10%;
}
.top-\[-20\%\]{
	top:
		-20%;
}
.top-\[-9999px\]{
	top:
		-9999px;
}
.top-\[11px\]{
	top:
		11px;
}
.top-\[20\%\]{
	top:
		20%;
}
.top-\[50\%\]{
	top:
		50%;
}
.top-\[70px\]{
	top:
		70px;
}
.top-\[8px\]{
	top:
		8px;
}
.-z-20{
	z-index:
		-20;
}
.z-0{
	z-index:
		0;
}
.z-10{
	z-index:
		10;
}
.z-100{
	z-index:
		100;
}
.z-20{
	z-index:
		20;
}
.z-30{
	z-index:
		30;
}
.z-40{
	z-index:
		40;
}
.z-50{
	z-index:
		50;
}
.z-\[-1\]{
	z-index:
		-1;
}
.z-\[10000\]{
	z-index:
		10000;
}
.z-\[1000\]{
	z-index:
		1000;
}
.z-\[1001\]{
	z-index:
		1001;
}
.z-\[1\]{
	z-index:
		1;
}
.z-\[999\]{
	z-index:
		999;
}
.z-\[99\]{
	z-index:
		99;
}
.z-auto{
	z-index:
		auto;
}
.order-first{
	order:
		-9999;
}
.col-span-1{
	grid-column:
		span 1 / span 1;
}
.col-span-2{
	grid-column:
		span 2 / span 2;
}
.col-span-full{
	grid-column:
		1 / -1;
}
.\!m-0{
	margin:
		0px !important;
}
.m-0{
	margin:
		0px;
}
.m-1{
	margin:
		0.25rem;
}
.m-10{
	margin:
		2.5rem;
}
.m-2{
	margin:
		0.5rem;
}
.m-20{
	margin:
		5rem;
}
.m-3{
	margin:
		0.75rem;
}
.m-4{
	margin:
		1rem;
}
.m-5{
	margin:
		1.25rem;
}
.m-6{
	margin:
		1.5rem;
}
.m-8{
	margin:
		2rem;
}
.m-auto{
	margin:
		auto;
}
.-mx-1{
	margin-left:
		-0.25rem;
	margin-right:
		-0.25rem;
}
.-mx-2{
	margin-left:
		-0.5rem;
	margin-right:
		-0.5rem;
}
.-mx-3{
	margin-left:
		-0.75rem;
	margin-right:
		-0.75rem;
}
.-mx-6{
	margin-left:
		-1.5rem;
	margin-right:
		-1.5rem;
}
.-my-0{
	margin-top:
		-0px;
	margin-bottom:
		-0px;
}
.-my-0\.5{
	margin-top:
		-0.125rem;
	margin-bottom:
		-0.125rem;
}
.-my-12{
	margin-top:
		-3rem;
	margin-bottom:
		-3rem;
}
.-my-16{
	margin-top:
		-4rem;
	margin-bottom:
		-4rem;
}
.mx-0{
	margin-left:
		0px;
	margin-right:
		0px;
}
.mx-1{
	margin-left:
		0.25rem;
	margin-right:
		0.25rem;
}
.mx-10{
	margin-left:
		2.5rem;
	margin-right:
		2.5rem;
}
.mx-12{
	margin-left:
		3rem;
	margin-right:
		3rem;
}
.mx-2{
	margin-left:
		0.5rem;
	margin-right:
		0.5rem;
}
.mx-3{
	margin-left:
		0.75rem;
	margin-right:
		0.75rem;
}
.mx-4{
	margin-left:
		1rem;
	margin-right:
		1rem;
}
.mx-5{
	margin-left:
		1.25rem;
	margin-right:
		1.25rem;
}
.mx-6{
	margin-left:
		1.5rem;
	margin-right:
		1.5rem;
}
.mx-8{
	margin-left:
		2rem;
	margin-right:
		2rem;
}
.mx-\[5px\]{
	margin-left:
		5px;
	margin-right:
		5px;
}
.mx-auto{
	margin-left:
		auto;
	margin-right:
		auto;
}
.my-0{
	margin-top:
		0px;
	margin-bottom:
		0px;
}
.my-0\.5{
	margin-top:
		0.125rem;
	margin-bottom:
		0.125rem;
}
.my-1{
	margin-top:
		0.25rem;
	margin-bottom:
		0.25rem;
}
.my-10{
	margin-top:
		2.5rem;
	margin-bottom:
		2.5rem;
}
.my-12{
	margin-top:
		3rem;
	margin-bottom:
		3rem;
}
.my-16{
	margin-top:
		4rem;
	margin-bottom:
		4rem;
}
.my-2{
	margin-top:
		0.5rem;
	margin-bottom:
		0.5rem;
}
.my-2\.5{
	margin-top:
		0.625rem;
	margin-bottom:
		0.625rem;
}
.my-3{
	margin-top:
		0.75rem;
	margin-bottom:
		0.75rem;
}
.my-4{
	margin-top:
		1rem;
	margin-bottom:
		1rem;
}
.my-5{
	margin-top:
		1.25rem;
	margin-bottom:
		1.25rem;
}
.my-6{
	margin-top:
		1.5rem;
	margin-bottom:
		1.5rem;
}
.my-8{
	margin-top:
		2rem;
	margin-bottom:
		2rem;
}
.my-auto{
	margin-top:
		auto;
	margin-bottom:
		auto;
}
.\!mb-1{
	margin-bottom:
		0.25rem !important;
}
.-mb-0{
	margin-bottom:
		-0px;
}
.-mb-0\.5{
	margin-bottom:
		-0.125rem;
}
.-mb-12{
	margin-bottom:
		-3rem;
}
.-mb-16{
	margin-bottom:
		-4rem;
}
.-mb-20{
	margin-bottom:
		-5rem;
}
.-mb-24{
	margin-bottom:
		-6rem;
}
.-mb-8{
	margin-bottom:
		-2rem;
}
.-mb-\[5px\]{
	margin-bottom:
		-5px;
}
.-ml-2{
	margin-left:
		-0.5rem;
}
.-ml-5{
	margin-left:
		-1.25rem;
}
.-mr-2{
	margin-right:
		-0.5rem;
}
.-mt-0{
	margin-top:
		-0px;
}
.-mt-0\.5{
	margin-top:
		-0.125rem;
}
.-mt-12{
	margin-top:
		-3rem;
}
.-mt-16{
	margin-top:
		-4rem;
}
.-mt-2{
	margin-top:
		-0.5rem;
}
.-mt-20{
	margin-top:
		-5rem;
}
.-mt-24{
	margin-top:
		-6rem;
}
.-mt-4{
	margin-top:
		-1rem;
}
.-mt-8{
	margin-top:
		-2rem;
}
.-mt-\[50px\]{
	margin-top:
		-50px;
}
.mb-0{
	margin-bottom:
		0px;
}
.mb-0\.5{
	margin-bottom:
		0.125rem;
}
.mb-1{
	margin-bottom:
		0.25rem;
}
.mb-10{
	margin-bottom:
		2.5rem;
}
.mb-12{
	margin-bottom:
		3rem;
}
.mb-16{
	margin-bottom:
		4rem;
}
.mb-2{
	margin-bottom:
		0.5rem;
}
.mb-2\.5{
	margin-bottom:
		0.625rem;
}
.mb-20{
	margin-bottom:
		5rem;
}
.mb-3{
	margin-bottom:
		0.75rem;
}
.mb-4{
	margin-bottom:
		1rem;
}
.mb-5{
	margin-bottom:
		1.25rem;
}
.mb-6{
	margin-bottom:
		1.5rem;
}
.mb-8{
	margin-bottom:
		2rem;
}
.mb-\[10\%\]{
	margin-bottom:
		10%;
}
.ml-0{
	margin-left:
		0px;
}
.ml-1{
	margin-left:
		0.25rem;
}
.ml-10{
	margin-left:
		2.5rem;
}
.ml-12{
	margin-left:
		3rem;
}
.ml-2{
	margin-left:
		0.5rem;
}
.ml-3{
	margin-left:
		0.75rem;
}
.ml-4{
	margin-left:
		1rem;
}
.ml-5{
	margin-left:
		1.25rem;
}
.ml-6{
	margin-left:
		1.5rem;
}
.ml-8{
	margin-left:
		2rem;
}
.ml-\[-250px\]{
	margin-left:
		-250px;
}
.ml-auto{
	margin-left:
		auto;
}
.mr-0{
	margin-right:
		0px;
}
.mr-1{
	margin-right:
		0.25rem;
}
.mr-10{
	margin-right:
		2.5rem;
}
.mr-16{
	margin-right:
		4rem;
}
.mr-2{
	margin-right:
		0.5rem;
}
.mr-3{
	margin-right:
		0.75rem;
}
.mr-4{
	margin-right:
		1rem;
}
.mr-5{
	margin-right:
		1.25rem;
}
.mr-6{
	margin-right:
		1.5rem;
}
.mr-7{
	margin-right:
		1.75rem;
}
.mr-8{
	margin-right:
		2rem;
}
.mr-\[10px\]{
	margin-right:
		10px;
}
.mr-\[15px\]{
	margin-right:
		15px;
}
.mr-\[7px\]{
	margin-right:
		7px;
}
.mt-0{
	margin-top:
		0px;
}
.mt-0\.5{
	margin-top:
		0.125rem;
}
.mt-1{
	margin-top:
		0.25rem;
}
.mt-10{
	margin-top:
		2.5rem;
}
.mt-12{
	margin-top:
		3rem;
}
.mt-16{
	margin-top:
		4rem;
}
.mt-2{
	margin-top:
		0.5rem;
}
.mt-20{
	margin-top:
		5rem;
}
.mt-3{
	margin-top:
		0.75rem;
}
.mt-32{
	margin-top:
		8rem;
}
.mt-4{
	margin-top:
		1rem;
}
.mt-5{
	margin-top:
		1.25rem;
}
.mt-6{
	margin-top:
		1.5rem;
}
.mt-8{
	margin-top:
		2rem;
}
.mt-\[-200px\]{
	margin-top:
		-200px;
}
.mt-\[10px\]{
	margin-top:
		10px;
}
.mt-\[13px\]{
	margin-top:
		13px;
}
.mt-\[5px\]{
	margin-top:
		5px;
}
.mt-auto{
	margin-top:
		auto;
}
.box-content{
	box-sizing:
		content-box;
}
.line-clamp-3{
	overflow:
		hidden;
	display:
		-webkit-box;
	-webkit-box-orient:
		vertical;
	-webkit-line-clamp:
		3;
}
.\!block{
	display:
		block !important;
}
.block{
	display:
		block;
}
.inline-block{
	display:
		inline-block;
}
.inline{
	display:
		inline;
}
.\!flex{
	display:
		flex !important;
}
.flex{
	display:
		flex;
}
.inline-flex{
	display:
		inline-flex;
}
.table{
	display:
		table;
}
.table-row{
	display:
		table-row;
}
.grid{
	display:
		grid;
}
.contents{
	display:
		contents;
}
.hidden{
	display:
		none;
}
.aspect-square{
	aspect-ratio:
		1 / 1;
}
.h-0{
	height:
		0px;
}
.h-1{
	height:
		0.25rem;
}
.h-1\/3{
	height:
		33.333333%;
}
.h-1\/4{
	height:
		25%;
}
.h-10{
	height:
		2.5rem;
}
.h-12{
	height:
		3rem;
}
.h-14{
	height:
		3.5rem;
}
.h-16{
	height:
		4rem;
}
.h-2{
	height:
		0.5rem;
}
.h-2\.5{
	height:
		0.625rem;
}
.h-20{
	height:
		5rem;
}
.h-24{
	height:
		6rem;
}
.h-3{
	height:
		0.75rem;
}
.h-3\/4{
	height:
		75%;
}
.h-32{
	height:
		8rem;
}
.h-36{
	height:
		9rem;
}
.h-4{
	height:
		1rem;
}
.h-40{
	height:
		10rem;
}
.h-44{
	height:
		11rem;
}
.h-48{
	height:
		12rem;
}
.h-5{
	height:
		1.25rem;
}
.h-56{
	height:
		14rem;
}
.h-6{
	height:
		1.5rem;
}
.h-64{
	height:
		16rem;
}
.h-7{
	height:
		1.75rem;
}
.h-72{
	height:
		18rem;
}
.h-8{
	height:
		2rem;
}
.h-9{
	height:
		2.25rem;
}
.h-96{
	height:
		24rem;
}
.h-\[100px\]{
	height:
		100px;
}
.h-\[111px\]{
	height:
		111px;
}
.h-\[120px\]{
	height:
		120px;
}
.h-\[140px\]{
	height:
		140px;
}
.h-\[150px\]{
	height:
		150px;
}
.h-\[15px\]{
	height:
		15px;
}
.h-\[164px\]{
	height:
		164px;
}
.h-\[192px\]{
	height:
		192px;
}
.h-\[230px\]{
	height:
		230px;
}
.h-\[270px\]{
	height:
		270px;
}
.h-\[2px\]{
	height:
		2px;
}
.h-\[300px\]{
	height:
		300px;
}
.h-\[350px\]{
	height:
		350px;
}
.h-\[35px\]{
	height:
		35px;
}
.h-\[400px\]{
	height:
		400px;
}
.h-\[420px\]{
	height:
		420px;
}
.h-\[460px\]{
	height:
		460px;
}
.h-\[55px\]{
	height:
		55px;
}
.h-\[76px\]{
	height:
		76px;
}
.h-\[800px\]{
	height:
		800px;
}
.h-\[calc\(100vh-138px\)\]{
	height:
		calc(100vh - 138px);
}
.h-\[inherit\]{
	height:
		inherit;
}
.h-auto{
	height:
		auto;
}
.h-fit{
	height:
		-moz-fit-content;
	height:
		fit-content;
}
.h-full{
	height:
		100%;
}
.h-max{
	height:
		-moz-max-content;
	height:
		max-content;
}
.h-px{
	height:
		1px;
}
.h-screen{
	height:
		100vh;
}
.max-h-12{
	max-height:
		3rem;
}
.max-h-3\/4{
	max-height:
		75vh;
}
.max-h-6{
	max-height:
		1.5rem;
}
.max-h-60{
	max-height:
		15rem;
}
.max-h-96{
	max-height:
		24rem;
}
.max-h-\[100\%\]{
	max-height:
		100%;
}
.max-h-\[285px\]{
	max-height:
		285px;
}
.max-h-\[300px\]{
	max-height:
		300px;
}
.max-h-\[350px\]{
	max-height:
		350px;
}
.max-h-\[380px\]{
	max-height:
		380px;
}
.max-h-\[90\%\]{
	max-height:
		90%;
}
.max-h-full{
	max-height:
		100%;
}
.max-h-none{
	max-height:
		none;
}
.max-h-screen{
	max-height:
		100vh;
}
.min-h-\[200px\]{
	min-height:
		200px;
}
.min-h-\[294px\]{
	min-height:
		294px;
}
.min-h-\[700px\]{
	min-height:
		700px;
}
.min-h-full{
	min-height:
		100%;
}
.min-h-screen{
	min-height:
		100vh;
}
.\!w-0{
	width:
		0px !important;
}
.\!w-\[10px\]{
	width:
		10px !important;
}
.\!w-\[21rem\]{
	width:
		21rem !important;
}
.\!w-full{
	width:
		100% !important;
}
.w-0{
	width:
		0px;
}
.w-1\/2{
	width:
		50%;
}
.w-1\/3{
	width:
		33.333333%;
}
.w-1\/4{
	width:
		25%;
}
.w-1\/5{
	width:
		20%;
}
.w-10{
	width:
		2.5rem;
}
.w-10\/12{
	width:
		83.333333%;
}
.w-11\/12{
	width:
		91.666667%;
}
.w-12{
	width:
		3rem;
}
.w-14{
	width:
		3.5rem;
}
.w-16{
	width:
		4rem;
}
.w-18{
	width:
		4.5rem;
}
.w-2{
	width:
		0.5rem;
}
.w-2\/3{
	width:
		66.666667%;
}
.w-2\/4{
	width:
		50%;
}
.w-2\/5{
	width:
		40%;
}
.w-20{
	width:
		5rem;
}
.w-24{
	width:
		6rem;
}
.w-3{
	width:
		0.75rem;
}
.w-3\/10{
	width:
		30%;
}
.w-3\/4{
	width:
		75%;
}
.w-3\/5{
	width:
		60%;
}
.w-32{
	width:
		8rem;
}
.w-4{
	width:
		1rem;
}
.w-4\/5{
	width:
		80%;
}
.w-40{
	width:
		10rem;
}
.w-44{
	width:
		11rem;
}
.w-48{
	width:
		12rem;
}
.w-5{
	width:
		1.25rem;
}
.w-5\/12{
	width:
		41.666667%;
}
.w-5\/6{
	width:
		83.333333%;
}
.w-6{
	width:
		1.5rem;
}
.w-6\/12{
	width:
		50%;
}
.w-60{
	width:
		15rem;
}
.w-64{
	width:
		16rem;
}
.w-7{
	width:
		1.75rem;
}
.w-7\/10{
	width:
		70%;
}
.w-7\/12{
	width:
		58.333333%;
}
.w-72{
	width:
		18rem;
}
.w-8{
	width:
		2rem;
}
.w-80{
	width:
		20rem;
}
.w-84{
	width:
		21rem;
}
.w-9\/12{
	width:
		75%;
}
.w-\[100px\]{
	width:
		100px;
}
.w-\[104px\]{
	width:
		104px;
}
.w-\[10px\]{
	width:
		10px;
}
.w-\[120px\]{
	width:
		120px;
}
.w-\[130px\]{
	width:
		130px;
}
.w-\[134px\]{
	width:
		134px;
}
.w-\[140px\]{
	width:
		140px;
}
.w-\[150px\]{
	width:
		150px;
}
.w-\[160px\]{
	width:
		160px;
}
.w-\[230px\]{
	width:
		230px;
}
.w-\[270px\]{
	width:
		270px;
}
.w-\[35px\]{
	width:
		35px;
}
.w-\[500px\]{
	width:
		500px;
}
.w-\[55px\]{
	width:
		55px;
}
.w-\[76px\]{
	width:
		76px;
}
.w-\[95vw\]{
	width:
		95vw;
}
.w-\[calc\(100\%-104px\)\]{
	width:
		calc(100% - 104px);
}
.w-\[calc\(100\%-3rem\)\]{
	width:
		calc(100% - 3rem);
}
.w-\[calc\(100\%-56px\)\]{
	width:
		calc(100% - 56px);
}
.w-\[inherit\]{
	width:
		inherit;
}
.w-auto{
	width:
		auto;
}
.w-fit{
	width:
		-moz-fit-content;
	width:
		fit-content;
}
.w-full{
	width:
		100%;
}
.w-max{
	width:
		-moz-max-content;
	width:
		max-content;
}
.min-w-18{
	min-width:
		4.5rem;
}
.min-w-\[200px\]{
	min-width:
		200px;
}
.min-w-\[280px\]{
	min-width:
		280px;
}
.min-w-\[300px\]{
	min-width:
		300px;
}
.min-w-\[90\%\]{
	min-width:
		90%;
}
.min-w-full{
	min-width:
		100%;
}
.max-w-1\/2{
	max-width:
		50%;
}
.max-w-150px{
	max-width:
		150px;
}
.max-w-2xl{
	max-width:
		42rem;
}
.max-w-3xl{
	max-width:
		48rem;
}
.max-w-4xl{
	max-width:
		56rem;
}
.max-w-5xl{
	max-width:
		64rem;
}
.max-w-7xl{
	max-width:
		80rem;
}
.max-w-\[1024px\]{
	max-width:
		1024px;
}
.max-w-\[112px\]{
	max-width:
		112px;
}
.max-w-\[130px\]{
	max-width:
		130px;
}
.max-w-\[160px\]{
	max-width:
		160px;
}
.max-w-\[180px\]{
	max-width:
		180px;
}
.max-w-\[200px\]{
	max-width:
		200px;
}
.max-w-\[285px\]{
	max-width:
		285px;
}
.max-w-\[30\%\]{
	max-width:
		30%;
}
.max-w-\[300px\]{
	max-width:
		300px;
}
.max-w-\[30px\]{
	max-width:
		30px;
}
.max-w-\[330px\]{
	max-width:
		330px;
}
.max-w-\[34rem\]{
	max-width:
		34rem;
}
.max-w-\[350px\]{
	max-width:
		350px;
}
.max-w-\[360px\]{
	max-width:
		360px;
}
.max-w-\[400px\]{
	max-width:
		400px;
}
.max-w-\[480px\]{
	max-width:
		480px;
}
.max-w-\[515px\]{
	max-width:
		515px;
}
.max-w-\[590px\]{
	max-width:
		590px;
}
.max-w-\[700px\]{
	max-width:
		700px;
}
.max-w-\[764px\]{
	max-width:
		764px;
}
.max-w-\[800px\]{
	max-width:
		800px;
}
.max-w-\[900px\]{
	max-width:
		900px;
}
.max-w-full{
	max-width:
		100%;
}
.max-w-lg{
	max-width:
		32rem;
}
.max-w-md{
	max-width:
		28rem;
}
.max-w-screen-xl{
	max-width:
		1280px;
}
.max-w-sm{
	max-width:
		24rem;
}
.max-w-xl{
	max-width:
		36rem;
}
.max-w-xs{
	max-width:
		20rem;
}
.flex-1{
	flex:
		1 1 0%;
}
.flex-initial{
	flex:
		0 1 auto;
}
.flex-none{
	flex:
		none;
}
.flex-shrink{
	flex-shrink:
		1;
}
.flex-shrink-0{
	flex-shrink:
		0;
}
.shrink{
	flex-shrink:
		1;
}
.shrink-0{
	flex-shrink:
		0;
}
.flex-grow{
	flex-grow:
		1;
}
.basis-10\/12{
	flex-basis:
		83.333333%;
}
.basis-2\/12{
	flex-basis:
		16.666667%;
}
.basis-6\/12{
	flex-basis:
		50%;
}
.-translate-x-1\/2{
	--tw-translate-x:
		-50%;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2{
	--tw-translate-y:
		-50%;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-0{
	--tw-translate-x:
		0px;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-4{
	--tw-translate-x:
		1rem;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[-0\.01px\]{
	--tw-translate-x:
		-0.01px;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[-45px\]{
	--tw-translate-x:
		-45px;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-1\/2{
	--tw-translate-y:
		50%;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-0\.5px\]{
	--tw-translate-y:
		-0.5px;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-54\.25px\]{
	--tw-translate-y:
		-54.25px;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-0{
	--tw-rotate:
		0deg;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180{
	--tw-rotate:
		180deg;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-45{
	--tw-rotate:
		45deg;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90{
	--tw-rotate:
		90deg;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[1\.4\]{
	--tw-scale-x:
		1.4;
	--tw-scale-y:
		1.4;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform{
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes bounce{
	0%, 100%{
		transform:
		translateY(-25%);
		animation-timing-function:
		cubic-bezier(0.8,0,1,1);
	}
	50%{
		transform:
		none;
		animation-timing-function:
		cubic-bezier(0,0,0.2,1);
	}
}
.animate-bounce{
	animation:
		bounce 1s infinite;
}
@keyframes pulse{
	50%{
		opacity:
		.5;
	}
}
.animate-pulse{
	animation:
		pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes spin{
	to{
		transform:
		rotate(360deg);
	}
}
.animate-spin{
	animation:
		spin 1s linear infinite;
}
.cursor-col-resize{
	cursor:
		col-resize;
}
.cursor-default{
	cursor:
		default;
}
.cursor-pointer{
	cursor:
		pointer;
}
.select-none{
	-webkit-user-select:
		none;
	   -moz-user-select:
		none;
	        user-select:
		none;
}
.resize{
	resize:
		both;
}
.-scroll-my-0{
	scroll-margin-top:
		-0px;
	scroll-margin-bottom:
		-0px;
}
.-scroll-my-0\.5{
	scroll-margin-top:
		-0.125rem;
	scroll-margin-bottom:
		-0.125rem;
}
.-scroll-my-12{
	scroll-margin-top:
		-3rem;
	scroll-margin-bottom:
		-3rem;
}
.-scroll-my-16{
	scroll-margin-top:
		-4rem;
	scroll-margin-bottom:
		-4rem;
}
.scroll-my-0{
	scroll-margin-top:
		0px;
	scroll-margin-bottom:
		0px;
}
.scroll-my-0\.5{
	scroll-margin-top:
		0.125rem;
	scroll-margin-bottom:
		0.125rem;
}
.scroll-my-12{
	scroll-margin-top:
		3rem;
	scroll-margin-bottom:
		3rem;
}
.scroll-my-16{
	scroll-margin-top:
		4rem;
	scroll-margin-bottom:
		4rem;
}
.-scroll-mb-0{
	scroll-margin-bottom:
		-0px;
}
.-scroll-mb-0\.5{
	scroll-margin-bottom:
		-0.125rem;
}
.-scroll-mb-12{
	scroll-margin-bottom:
		-3rem;
}
.-scroll-mb-16{
	scroll-margin-bottom:
		-4rem;
}
.-scroll-mt-0{
	scroll-margin-top:
		-0px;
}
.-scroll-mt-0\.5{
	scroll-margin-top:
		-0.125rem;
}
.-scroll-mt-12{
	scroll-margin-top:
		-3rem;
}
.-scroll-mt-16{
	scroll-margin-top:
		-4rem;
}
.scroll-mb-0{
	scroll-margin-bottom:
		0px;
}
.scroll-mb-0\.5{
	scroll-margin-bottom:
		0.125rem;
}
.scroll-mb-12{
	scroll-margin-bottom:
		3rem;
}
.scroll-mb-16{
	scroll-margin-bottom:
		4rem;
}
.scroll-mt-0{
	scroll-margin-top:
		0px;
}
.scroll-mt-0\.5{
	scroll-margin-top:
		0.125rem;
}
.scroll-mt-12{
	scroll-margin-top:
		3rem;
}
.scroll-mt-16{
	scroll-margin-top:
		4rem;
}
.scroll-mt-\[100px\]{
	scroll-margin-top:
		100px;
}
.list-decimal{
	list-style-type:
		decimal;
}
.list-disc{
	list-style-type:
		disc;
}
.list-none{
	list-style-type:
		none;
}
.appearance-none{
	-webkit-appearance:
		none;
	   -moz-appearance:
		none;
	        appearance:
		none;
}
.columns-2{
	-moz-columns:
		2;
	     columns:
		2;
}
.auto-cols-auto{
	grid-auto-columns:
		auto;
}
.auto-cols-fr{
	grid-auto-columns:
		minmax(0, 1fr);
}
.grid-flow-row{
	grid-auto-flow:
		row;
}
.grid-flow-col{
	grid-auto-flow:
		column;
}
.auto-rows-fr{
	grid-auto-rows:
		minmax(0, 1fr);
}
.auto-rows-max{
	grid-auto-rows:
		max-content;
}
.auto-rows-min{
	grid-auto-rows:
		min-content;
}
.grid-cols-1{
	grid-template-columns:
		repeat(1, minmax(0, 1fr));
}
.grid-cols-2{
	grid-template-columns:
		repeat(2, minmax(0, 1fr));
}
.grid-rows-3{
	grid-template-rows:
		repeat(3, minmax(0, 1fr));
}
.flex-row{
	flex-direction:
		row;
}
.flex-row-reverse{
	flex-direction:
		row-reverse;
}
.flex-col{
	flex-direction:
		column;
}
.flex-col-reverse{
	flex-direction:
		column-reverse;
}
.flex-wrap{
	flex-wrap:
		wrap;
}
.flex-nowrap{
	flex-wrap:
		nowrap;
}
.place-content-center{
	place-content:
		center;
}
.place-content-start{
	place-content:
		start;
}
.place-items-center{
	place-items:
		center;
}
.content-center{
	align-content:
		center;
}
.items-start{
	align-items:
		flex-start;
}
.items-end{
	align-items:
		flex-end;
}
.items-center{
	align-items:
		center;
}
.items-stretch{
	align-items:
		stretch;
}
.justify-start{
	justify-content:
		flex-start;
}
.justify-end{
	justify-content:
		flex-end;
}
.justify-center{
	justify-content:
		center;
}
.justify-between{
	justify-content:
		space-between;
}
.justify-around{
	justify-content:
		space-around;
}
.justify-evenly{
	justify-content:
		space-evenly;
}
.justify-items-start{
	justify-items:
		start;
}
.justify-items-center{
	justify-items:
		center;
}
.gap-1{
	gap:
		0.25rem;
}
.gap-10{
	gap:
		2.5rem;
}
.gap-2{
	gap:
		0.5rem;
}
.gap-3{
	gap:
		0.75rem;
}
.gap-4{
	gap:
		1rem;
}
.gap-6{
	gap:
		1.5rem;
}
.gap-8{
	gap:
		2rem;
}
.gap-\[5px\]{
	gap:
		5px;
}
.gap-x-12{
	-moz-column-gap:
		3rem;
	     column-gap:
		3rem;
}
.gap-x-5{
	-moz-column-gap:
		1.25rem;
	     column-gap:
		1.25rem;
}
.gap-y-2{
	row-gap:
		0.5rem;
}
.gap-y-3{
	row-gap:
		0.75rem;
}
.gap-y-4{
	row-gap:
		1rem;
}
.gap-y-5{
	row-gap:
		1.25rem;
}
.space-x-2 > :not([hidden]) ~ :not([hidden]){
	--tw-space-x-reverse:
		0;
	margin-right:
		calc(0.5rem * var(--tw-space-x-reverse));
	margin-left:
		calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]){
	--tw-space-x-reverse:
		0;
	margin-right:
		calc(1rem * var(--tw-space-x-reverse));
	margin-left:
		calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-8 > :not([hidden]) ~ :not([hidden]){
	--tw-space-x-reverse:
		0;
	margin-right:
		calc(2rem * var(--tw-space-x-reverse));
	margin-left:
		calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]){
	--tw-space-y-reverse:
		0;
	margin-top:
		calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom:
		calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]){
	--tw-space-y-reverse:
		0;
	margin-top:
		calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom:
		calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-20 > :not([hidden]) ~ :not([hidden]){
	--tw-space-y-reverse:
		0;
	margin-top:
		calc(5rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom:
		calc(5rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]){
	--tw-space-y-reverse:
		0;
	margin-top:
		calc(1rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom:
		calc(1rem * var(--tw-space-y-reverse));
}
.place-self-start{
	place-self:
		start;
}
.self-start{
	align-self:
		flex-start;
}
.self-end{
	align-self:
		flex-end;
}
.self-center{
	align-self:
		center;
}
.overflow-auto{
	overflow:
		auto;
}
.overflow-hidden{
	overflow:
		hidden;
}
.overflow-visible{
	overflow:
		visible;
}
.overflow-x-auto{
	overflow-x:
		auto;
}
.overflow-y-auto{
	overflow-y:
		auto;
}
.overflow-y-scroll{
	overflow-y:
		scroll;
}
.scroll-smooth{
	scroll-behavior:
		smooth;
}
.truncate{
	overflow:
		hidden;
	text-overflow:
		ellipsis;
	white-space:
		nowrap;
}
.whitespace-nowrap{
	white-space:
		nowrap;
}
.whitespace-pre-line{
	white-space:
		pre-line;
}
.break-words{
	overflow-wrap:
		break-word;
}
.break-all{
	word-break:
		break-all;
}
.\!rounded-md{
	border-radius:
		0.375rem !important;
}
.rounded{
	border-radius:
		0.25rem;
}
.rounded-3xl{
	border-radius:
		1.5rem;
}
.rounded-\[0\.3rem\]{
	border-radius:
		0.3rem;
}
.rounded-\[25\%\]{
	border-radius:
		25%;
}
.rounded-\[50\%\]{
	border-radius:
		50%;
}
.rounded-\[54px\]{
	border-radius:
		54px;
}
.rounded-full{
	border-radius:
		9999px;
}
.rounded-lg{
	border-radius:
		0.5rem;
}
.rounded-md{
	border-radius:
		0.375rem;
}
.rounded-none{
	border-radius:
		0px;
}
.rounded-sm{
	border-radius:
		3px;
}
.rounded-b-lg{
	border-bottom-right-radius:
		0.5rem;
	border-bottom-left-radius:
		0.5rem;
}
.rounded-b-none{
	border-bottom-right-radius:
		0px;
	border-bottom-left-radius:
		0px;
}
.rounded-l{
	border-top-left-radius:
		0.25rem;
	border-bottom-left-radius:
		0.25rem;
}
.rounded-l-lg{
	border-top-left-radius:
		0.5rem;
	border-bottom-left-radius:
		0.5rem;
}
.rounded-r-lg{
	border-top-right-radius:
		0.5rem;
	border-bottom-right-radius:
		0.5rem;
}
.rounded-r-none{
	border-top-right-radius:
		0px;
	border-bottom-right-radius:
		0px;
}
.rounded-t-lg{
	border-top-left-radius:
		0.5rem;
	border-top-right-radius:
		0.5rem;
}
.rounded-t-none{
	border-top-left-radius:
		0px;
	border-top-right-radius:
		0px;
}
.rounded-bl-lg{
	border-bottom-left-radius:
		0.5rem;
}
.rounded-br-lg{
	border-bottom-right-radius:
		0.5rem;
}
.rounded-tl-lg{
	border-top-left-radius:
		0.5rem;
}
.rounded-tr-lg{
	border-top-right-radius:
		0.5rem;
}
.\!border{
	border-width:
		1px !important;
}
.border{
	border-width:
		1px;
}
.border-0{
	border-width:
		0px;
}
.border-2{
	border-width:
		2px;
}
.border-3{
	border-width:
		3px;
}
.border-4{
	border-width:
		4px;
}
.border-6{
	border-width:
		6px;
}
.border-\[5px\]{
	border-width:
		5px;
}
.border-\[7px\]{
	border-width:
		7px;
}
.border-b{
	border-bottom-width:
		1px;
}
.border-b-0{
	border-bottom-width:
		0px;
}
.border-b-2{
	border-bottom-width:
		2px;
}
.border-b-6{
	border-bottom-width:
		6px;
}
.border-b-\[2\.5px\]{
	border-bottom-width:
		2.5px;
}
.border-l-0{
	border-left-width:
		0px;
}
.border-l-2{
	border-left-width:
		2px;
}
.border-l-8{
	border-left-width:
		8px;
}
.border-r{
	border-right-width:
		1px;
}
.border-r-0{
	border-right-width:
		0px;
}
.border-r-\[2\.5px\]{
	border-right-width:
		2.5px;
}
.border-t{
	border-top-width:
		1px;
}
.border-t-0{
	border-top-width:
		0px;
}
.border-t-2{
	border-top-width:
		2px;
}
.border-t-\[1px\]{
	border-top-width:
		1px;
}
.\!border-solid{
	border-style:
		solid !important;
}
.border-solid{
	border-style:
		solid;
}
.border-dashed{
	border-style:
		dashed;
}
.border-none{
	border-style:
		none;
}
.\!border-\[\#a0aec0\]{
	--tw-border-opacity:
		1 !important;
	border-color:
		rgb(160 174 192 / var(--tw-border-opacity)) !important;
}
.\!border-\[\#ea0101\]{
	--tw-border-opacity:
		1 !important;
	border-color:
		rgb(234 1 1 / var(--tw-border-opacity)) !important;
}
.border-\[\#ccc\]{
	--tw-border-opacity:
		1;
	border-color:
		rgb(204 204 204 / var(--tw-border-opacity));
}
.border-\[lightgrey\]{
	--tw-border-opacity:
		1;
	border-color:
		rgb(211 211 211 / var(--tw-border-opacity));
}
.border-advocate{
	--tw-border-opacity:
		1;
	border-color:
		rgb(239 125 0 / var(--tw-border-opacity));
}
.border-bewormready{
	--tw-border-opacity:
		1;
	border-color:
		rgb(252 1 157 / var(--tw-border-opacity));
}
.border-bexacat_dark_grey{
	--tw-border-opacity:
		1;
	border-color:
		rgb(38 38 38 / var(--tw-border-opacity));
}
.border-black{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 0 0 / var(--tw-border-opacity));
}
.border-blue-700{
	--tw-border-opacity:
		1;
	border-color:
		rgb(128 146 158 / var(--tw-border-opacity));
}
.border-blue-800{
	--tw-border-opacity:
		1;
	border-color:
		rgb(77 102 120 / var(--tw-border-opacity));
}
.border-blue-900{
	--tw-border-opacity:
		1;
	border-color:
		rgb(2 37 62 / var(--tw-border-opacity));
}
.border-capstar{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 99 62 / var(--tw-border-opacity));
}
.border-civ{
	--tw-border-opacity:
		1;
	border-color:
		rgb(207 182 46 / var(--tw-border-opacity));
}
.border-clean_up_2{
	--tw-border-opacity:
		1;
	border-color:
		rgb(146 192 80 / var(--tw-border-opacity));
}
.border-comfortis{
	--tw-border-opacity:
		1;
	border-color:
		rgb(240 49 39 / var(--tw-border-opacity));
}
.border-credelio{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 132 61 / var(--tw-border-opacity));
}
.border-credelio_quattro_frisbee{
	--tw-border-opacity:
		1;
	border-color:
		rgb(236 0 140 / var(--tw-border-opacity));
}
.border-credelio_quattro_golden{
	--tw-border-opacity:
		1;
	border-color:
		rgb(246 138 51 / var(--tw-border-opacity));
}
.border-credelio_quattro_grass_green{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 149 72 / var(--tw-border-opacity));
}
.border-credelio_quattro_guard_dog{
	--tw-border-opacity:
		1;
	border-color:
		rgb(15 51 32 / var(--tw-border-opacity));
}
.border-credelio_quattro_hunter_green{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 100 60 / var(--tw-border-opacity));
}
.border-credelio_quattro_milk_bone{
	--tw-border-opacity:
		1;
	border-color:
		rgb(237 236 222 / var(--tw-border-opacity));
}
.border-credelio_quattro_park_green{
	--tw-border-opacity:
		1;
	border-color:
		rgb(148 206 149 / var(--tw-border-opacity));
}
.border-credelio_quattro_sky_blue{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 153 216 / var(--tw-border-opacity));
}
.border-credelio_quattro_sunny_day{
	--tw-border-opacity:
		1;
	border-color:
		rgb(234 172 24 / var(--tw-border-opacity));
}
.border-credelio_quattro_tennis_ball_green{
	--tw-border-opacity:
		1;
	border-color:
		rgb(128 187 79 / var(--tw-border-opacity));
}
.border-current{
	border-color:
		currentColor;
}
.border-cydectin{
	--tw-border-opacity:
		1;
	border-color:
		rgb(92 69 134 / var(--tw-border-opacity));
}
.border-elanco-beige{
	--tw-border-opacity:
		1;
	border-color:
		rgb(213 196 175 / var(--tw-border-opacity));
}
.border-elanco-blue{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 114 206 / var(--tw-border-opacity));
}
.border-elmaro_navy_blue{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 69 127 / var(--tw-border-opacity));
}
.border-elmaro_pink{
	--tw-border-opacity:
		1;
	border-color:
		rgb(235 123 174 / var(--tw-border-opacity));
}
.border-elmaro_yellow{
	--tw-border-opacity:
		1;
	border-color:
		rgb(251 186 0 / var(--tw-border-opacity));
}
.border-elura{
	--tw-border-opacity:
		1;
	border-color:
		rgb(171 19 105 / var(--tw-border-opacity));
}
.border-entyce{
	--tw-border-opacity:
		1;
	border-color:
		rgb(68 0 153 / var(--tw-border-opacity));
}
.border-experior{
	--tw-border-opacity:
		1;
	border-color:
		rgb(119 192 67 / var(--tw-border-opacity));
}
.border-galliprant{
	--tw-border-opacity:
		1;
	border-color:
		rgb(191 13 62 / var(--tw-border-opacity));
}
.border-galliprant_red{
	--tw-border-opacity:
		1;
	border-color:
		rgb(209 25 71 / var(--tw-border-opacity));
}
.border-gray-200{
	--tw-border-opacity:
		1;
	border-color:
		rgb(237 237 237 / var(--tw-border-opacity));
}
.border-gray-300{
	--tw-border-opacity:
		1;
	border-color:
		rgb(224 224 224 / var(--tw-border-opacity));
}
.border-gray-400{
	--tw-border-opacity:
		1;
	border-color:
		rgb(211 211 211 / var(--tw-border-opacity));
}
.border-gray-500{
	--tw-border-opacity:
		1;
	border-color:
		rgb(188 186 186 / var(--tw-border-opacity));
}
.border-gray-600{
	--tw-border-opacity:
		1;
	border-color:
		rgb(112 112 112 / var(--tw-border-opacity));
}
.border-gray-700{
	--tw-border-opacity:
		1;
	border-color:
		rgb(211 211 211 / var(--tw-border-opacity));
}
.border-green-600{
	--tw-border-opacity:
		1;
	border-color:
		rgb(31 136 21 / var(--tw-border-opacity));
}
.border-interceptor_plus{
	--tw-border-opacity:
		1;
	border-color:
		rgb(255 221 61 / var(--tw-border-opacity));
}
.border-light-grey{
	--tw-border-opacity:
		1;
	border-color:
		rgb(204 204 204 / var(--tw-border-opacity));
}
.border-micotil{
	--tw-border-opacity:
		1;
	border-color:
		rgb(19 181 234 / var(--tw-border-opacity));
}
.border-midnight{
	--tw-border-opacity:
		1;
	border-color:
		rgb(3 51 87 / var(--tw-border-opacity));
}
.border-orange-500{
	--tw-border-opacity:
		1;
	border-color:
		rgb(238 125 0 / var(--tw-border-opacity));
}
.border-pet-green{
	--tw-border-opacity:
		1;
	border-color:
		rgb(37 136 33 / var(--tw-border-opacity));
}
.border-pet-midnight{
	--tw-border-opacity:
		1;
	border-color:
		rgb(2 21 70 / var(--tw-border-opacity));
}
.border-pet_protect_allergy{
	--tw-border-opacity:
		1;
	border-color:
		rgb(112 185 37 / var(--tw-border-opacity));
}
.border-pet_protect_blue{
	--tw-border-opacity:
		1;
	border-color:
		rgb(73 178 230 / var(--tw-border-opacity));
}
.border-pet_protect_calm{
	--tw-border-opacity:
		1;
	border-color:
		rgb(192 86 148 / var(--tw-border-opacity));
}
.border-pet_protect_green{
	--tw-border-opacity:
		1;
	border-color:
		rgb(19 134 61 / var(--tw-border-opacity));
}
.border-pet_protect_red{
	--tw-border-opacity:
		1;
	border-color:
		rgb(249 66 58 / var(--tw-border-opacity));
}
.border-pet_protect_skin{
	--tw-border-opacity:
		1;
	border-color:
		rgb(37 176 231 / var(--tw-border-opacity));
}
.border-primary{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 114 206 / var(--tw-border-opacity));
}
.border-red-500{
	--tw-border-opacity:
		1;
	border-color:
		rgb(234 1 1 / var(--tw-border-opacity));
}
.border-scour_bos{
	--tw-border-opacity:
		1;
	border-color:
		rgb(106 40 117 / var(--tw-border-opacity));
}
.border-secondary{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 171 156 / var(--tw-border-opacity));
}
.border-theme-main{
	--tw-border-opacity:
		1;
	border-color:
		rgba(var(--color-theme), var(--tw-border-opacity));
}
.border-trifexis{
	--tw-border-opacity:
		1;
	border-color:
		rgb(168 173 0 / var(--tw-border-opacity));
}
.border-varenzin_blue{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 86 184 / var(--tw-border-opacity));
}
.border-varenzin_purple{
	--tw-border-opacity:
		1;
	border-color:
		rgb(106 72 142 / var(--tw-border-opacity));
}
.border-vet-purple{
	--tw-border-opacity:
		1;
	border-color:
		rgb(56 24 162 / var(--tw-border-opacity));
}
.border-vira_shield{
	--tw-border-opacity:
		1;
	border-color:
		rgb(224 90 40 / var(--tw-border-opacity));
}
.border-white{
	--tw-border-opacity:
		1;
	border-color:
		rgb(255 255 255 / var(--tw-border-opacity));
}
.border-zenrelia_accent{
	--tw-border-opacity:
		1;
	border-color:
		rgb(192 210 154 / var(--tw-border-opacity));
}
.border-zenrelia_contrast{
	--tw-border-opacity:
		1;
	border-color:
		rgb(166 140 250 / var(--tw-border-opacity));
}
.border-zenrelia_primary{
	--tw-border-opacity:
		1;
	border-color:
		rgb(33 84 71 / var(--tw-border-opacity));
}
.border-zenrelia_secondary{
	--tw-border-opacity:
		1;
	border-color:
		rgb(145 204 18 / var(--tw-border-opacity));
}
.border-zenrelia_sku1{
	--tw-border-opacity:
		1;
	border-color:
		rgb(97 133 130 / var(--tw-border-opacity));
}
.border-zenrelia_sku2{
	--tw-border-opacity:
		1;
	border-color:
		rgb(135 82 199 / var(--tw-border-opacity));
}
.border-zenrelia_sku3{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 169 158 / var(--tw-border-opacity));
}
.border-zenrelia_sku4{
	--tw-border-opacity:
		1;
	border-color:
		rgb(198 181 255 / var(--tw-border-opacity));
}
.border-b-white{
	--tw-border-opacity:
		1;
	border-bottom-color:
		rgb(255 255 255 / var(--tw-border-opacity));
}
.border-r-white{
	--tw-border-opacity:
		1;
	border-right-color:
		rgb(255 255 255 / var(--tw-border-opacity));
}
.\!bg-transparent{
	background-color:
		rgba(0, 0, 0, 0) !important;
}
.\!bg-white{
	--tw-bg-opacity:
		1 !important;
	background-color:
		rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}
.bg-\[\#00000080\]{
	background-color:
		#00000080;
}
.bg-\[\#215447\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(33 84 71 / var(--tw-bg-opacity));
}
.bg-\[\#258821AA\]\/\[0\.4\]{
	background-color:
		rgb(37 136 33 / 0.4);
}
.bg-\[\#91cc12\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(145 204 18 / var(--tw-bg-opacity));
}
.bg-\[\#CEEDC7\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(206 237 199 / var(--tw-bg-opacity));
}
.bg-\[\#CFEAC640\]{
	background-color:
		#CFEAC640;
}
.bg-\[\#CFEAC6\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(207 234 198 / var(--tw-bg-opacity));
}
.bg-\[\#E2F2DD\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(226 242 221 / var(--tw-bg-opacity));
}
.bg-\[\#EBEBEB\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(235 235 235 / var(--tw-bg-opacity));
}
.bg-\[\#EBEBEB\]\/50{
	background-color:
		rgb(235 235 235 / 0.5);
}
.bg-\[\#F6FBF4\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(246 251 244 / var(--tw-bg-opacity));
}
.bg-\[\#F8F8F8\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(248 248 248 / var(--tw-bg-opacity));
}
.bg-\[\#FD8A8A\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(253 138 138 / var(--tw-bg-opacity));
}
.bg-\[\#FDF7C3\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(253 247 195 / var(--tw-bg-opacity));
}
.bg-\[\#ebebeb\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(235 235 235 / var(--tw-bg-opacity));
}
.bg-\[\#ffe5e5\]{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 229 229 / var(--tw-bg-opacity));
}
.bg-\[rgba\(28\2c 28\2c 28\2c 0\.76\)\]{
	background-color:
		rgba(28,28,28,0.76);
}
.bg-adtab_black{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(29 29 27 / var(--tw-bg-opacity));
}
.bg-adtab_white{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-adtab_yellow{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 212 0 / var(--tw-bg-opacity));
}
.bg-advocate{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(239 125 0 / var(--tw-bg-opacity));
}
.bg-advocate_orange{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(229 114 0 / var(--tw-bg-opacity));
}
.bg-agita_10wg{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(126 160 196 / var(--tw-bg-opacity));
}
.bg-aviblue_dark_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(7 63 101 / var(--tw-bg-opacity));
}
.bg-aviblue_light_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(65 192 240 / var(--tw-bg-opacity));
}
.bg-aviblue_mid_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 120 191 / var(--tw-bg-opacity));
}
.bg-avipro_grey{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(146 146 146 / var(--tw-bg-opacity));
}
.bg-avipro_red{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(213 19 23 / var(--tw-bg-opacity));
}
.bg-baseline{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(219 49 104 / var(--tw-bg-opacity));
}
.bg-baytril_100{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 143 135 / var(--tw-bg-opacity));
}
.bg-bewormready{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(252 1 157 / var(--tw-bg-opacity));
}
.bg-bexacat{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(204 39 176 / var(--tw-bg-opacity));
}
.bg-bexacat_dark_grey{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(38 38 38 / var(--tw-bg-opacity));
}
.bg-bexacat_orange{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 158 24 / var(--tw-bg-opacity));
}
.bg-black{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 0 0 / var(--tw-bg-opacity));
}
.bg-black\/50{
	background-color:
		rgb(0 0 0 / 0.5);
}
.bg-blue-100{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(235 247 255 / var(--tw-bg-opacity));
}
.bg-blue-400{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 127 177 / var(--tw-bg-opacity));
}
.bg-blue-500{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 114 206 / var(--tw-bg-opacity));
}
.bg-blue-700{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(128 146 158 / var(--tw-bg-opacity));
}
.bg-blue-800{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(77 102 120 / var(--tw-bg-opacity));
}
.bg-blue-900{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(2 37 62 / var(--tw-bg-opacity));
}
.bg-blue-transparent{
	background-color:
		rgba(2, 37, 62, 0.95);
}
.bg-bovaer{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(2 125 80 / var(--tw-bg-opacity));
}
.bg-capstar{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 99 62 / var(--tw-bg-opacity));
}
.bg-civ{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(207 182 46 / var(--tw-bg-opacity));
}
.bg-claro_neptra{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(82 40 115 / var(--tw-bg-opacity));
}
.bg-clik_dark_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(31 53 96 / var(--tw-bg-opacity));
}
.bg-clik_light_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(12 120 189 / var(--tw-bg-opacity));
}
.bg-clik_yellow{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(219 147 0 / var(--tw-bg-opacity));
}
.bg-coliprotec_teal{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 129 163 / var(--tw-bg-opacity));
}
.bg-comfortis{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(240 49 39 / var(--tw-bg-opacity));
}
.bg-credelio{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 132 61 / var(--tw-bg-opacity));
}
.bg-credelio_quattro_frisbee{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(236 0 140 / var(--tw-bg-opacity));
}
.bg-credelio_quattro_golden{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(246 138 51 / var(--tw-bg-opacity));
}
.bg-credelio_quattro_grass_green{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 149 72 / var(--tw-bg-opacity));
}
.bg-credelio_quattro_guard_dog{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(15 51 32 / var(--tw-bg-opacity));
}
.bg-credelio_quattro_hunter_green{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 100 60 / var(--tw-bg-opacity));
}
.bg-credelio_quattro_milk_bone{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(237 236 222 / var(--tw-bg-opacity));
}
.bg-credelio_quattro_park_green{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(148 206 149 / var(--tw-bg-opacity));
}
.bg-credelio_quattro_sky_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 153 216 / var(--tw-bg-opacity));
}
.bg-credelio_quattro_sunny_day{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(234 172 24 / var(--tw-bg-opacity));
}
.bg-credelio_quattro_tennis_ball_green{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(128 187 79 / var(--tw-bg-opacity));
}
.bg-default-light-background{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(213 196 175 / var(--tw-bg-opacity));
}
.bg-droncit_red{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(175 42 70 / var(--tw-bg-opacity));
}
.bg-elanco-beige{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(213 196 175 / var(--tw-bg-opacity));
}
.bg-elanco-blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 114 206 / var(--tw-bg-opacity));
}
.bg-elanco-green{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(14 195 164 / var(--tw-bg-opacity));
}
.bg-elmaro_navy_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 69 127 / var(--tw-bg-opacity));
}
.bg-elmaro_pink{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(235 123 174 / var(--tw-bg-opacity));
}
.bg-elmaro_yellow{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(251 186 0 / var(--tw-bg-opacity));
}
.bg-elura{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(171 19 105 / var(--tw-bg-opacity));
}
.bg-entyce{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(68 0 153 / var(--tw-bg-opacity));
}
.bg-evosure{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(229 102 6 / var(--tw-bg-opacity));
}
.bg-feedaid{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(114 151 134 / var(--tw-bg-opacity));
}
.bg-flubenvet{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(120 142 29 / var(--tw-bg-opacity));
}
.bg-full_value_pork_primary_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 66 103 / var(--tw-bg-opacity));
}
.bg-full_value_pork_secondary_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(48 169 215 / var(--tw-bg-opacity));
}
.bg-galliprant{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(191 13 62 / var(--tw-bg-opacity));
}
.bg-galliprant_red{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(209 25 71 / var(--tw-bg-opacity));
}
.bg-galliprant_yellow{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 198 0 / var(--tw-bg-opacity));
}
.bg-gallipro_fit_darkblue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(20 44 70 / var(--tw-bg-opacity));
}
.bg-gallipro_fit_green{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(158 177 132 / var(--tw-bg-opacity));
}
.bg-gallipro_fit_orange{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(239 121 0 / var(--tw-bg-opacity));
}
.bg-gallipro_fit_yellow{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(251 186 0 / var(--tw-bg-opacity));
}
.bg-gray-100{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(245 245 245 / var(--tw-bg-opacity));
}
.bg-gray-200{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(237 237 237 / var(--tw-bg-opacity));
}
.bg-gray-300{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(224 224 224 / var(--tw-bg-opacity));
}
.bg-gray-400{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(211 211 211 / var(--tw-bg-opacity));
}
.bg-green-600{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(31 136 21 / var(--tw-bg-opacity));
}
.bg-hemicell{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(48 177 157 / var(--tw-bg-opacity));
}
.bg-hemicell_red{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(179 43 46 / var(--tw-bg-opacity));
}
.bg-htsi{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 66 104 / var(--tw-bg-opacity));
}
.bg-kexxtone{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 208 0 / var(--tw-bg-opacity));
}
.bg-light-blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(217 234 248 / var(--tw-bg-opacity));
}
.bg-light_gray{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(242 242 242 / var(--tw-bg-opacity));
}
.bg-lipinate{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(70 76 62 / var(--tw-bg-opacity));
}
.bg-main{
	background-color:
		var(--color-bg-main);
}
.bg-maxiban_{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(211 18 69 / var(--tw-bg-opacity));
}
.bg-micotil{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(19 181 234 / var(--tw-bg-opacity));
}
.bg-mid-beige{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(238 232 223 / var(--tw-bg-opacity));
}
.bg-midnight-blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(1 19 71 / var(--tw-bg-opacity));
}
.bg-neptra_green{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(199 213 48 / var(--tw-bg-opacity));
}
.bg-onsior{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 169 206 / var(--tw-bg-opacity));
}
.bg-onsior_advantix_light{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(249 229 235 / var(--tw-bg-opacity));
}
.bg-onsior_cool_gray_10{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(99 102 106 / var(--tw-bg-opacity));
}
.bg-onsior_cool_gray_6c{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(167 168 170 / var(--tw-bg-opacity));
}
.bg-onsior_dark_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 53 148 / var(--tw-bg-opacity));
}
.bg-onsior_dark_gray{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(44 42 41 / var(--tw-bg-opacity));
}
.bg-onsior_dark_red{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(157 34 53 / var(--tw-bg-opacity));
}
.bg-onsior_dark_violet{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(60 16 83 / var(--tw-bg-opacity));
}
.bg-onsior_lime_green{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 177 64 / var(--tw-bg-opacity));
}
.bg-onsior_rhodamine_red{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(225 0 152 / var(--tw-bg-opacity));
}
.bg-onsior_tangerine{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 184 28 / var(--tw-bg-opacity));
}
.bg-onsior_vivid_orange{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 103 31 / var(--tw-bg-opacity));
}
.bg-orange-500{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(238 125 0 / var(--tw-bg-opacity));
}
.bg-parvo{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(43 113 184 / var(--tw-bg-opacity));
}
.bg-pet-green{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(37 136 33 / var(--tw-bg-opacity));
}
.bg-pet-green-light{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(202 227 200 / var(--tw-bg-opacity));
}
.bg-pet-midnight{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(2 21 70 / var(--tw-bg-opacity));
}
.bg-pet_protect_allergy{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(112 185 37 / var(--tw-bg-opacity));
}
.bg-pet_protect_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(73 178 230 / var(--tw-bg-opacity));
}
.bg-pet_protect_calm{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(192 86 148 / var(--tw-bg-opacity));
}
.bg-pet_protect_green{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(19 134 61 / var(--tw-bg-opacity));
}
.bg-pet_protect_red{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(249 66 58 / var(--tw-bg-opacity));
}
.bg-pet_protect_skin{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(37 176 231 / var(--tw-bg-opacity));
}
.bg-pili_shield_plus_c{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(127 38 41 / var(--tw-bg-opacity));
}
.bg-pradalex{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(38 208 124 / var(--tw-bg-opacity));
}
.bg-primary{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 114 206 / var(--tw-bg-opacity));
}
.bg-proteck{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(204 144 0 / var(--tw-bg-opacity));
}
.bg-purple-light{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(234 230 245 / var(--tw-bg-opacity));
}
.bg-red-300{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(250 231 229 / var(--tw-bg-opacity));
}
.bg-red-500{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(234 1 1 / var(--tw-bg-opacity));
}
.bg-red-600{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(190 2 2 / var(--tw-bg-opacity));
}
.bg-rhini_shield{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 147 131 / var(--tw-bg-opacity));
}
.bg-salmonella_360{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(238 125 0 / var(--tw-bg-opacity));
}
.bg-secondary{
	background-color:
		var(--color-bg-secondary);
}
.bg-seresto{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 89 124 / var(--tw-bg-opacity));
}
.bg-swine_awake{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(123 35 121 / var(--tw-bg-opacity));
}
.bg-tempo_one_percent_dust{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(117 188 67 / var(--tw-bg-opacity));
}
.bg-theme-main{
	--tw-bg-opacity:
		1;
	background-color:
		rgba(var(--color-theme), var(--tw-bg-opacity));
}
.bg-tracer{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(237 3 124 / var(--tw-bg-opacity));
}
.bg-transparent{
	background-color:
		rgba(0, 0, 0, 0);
}
.bg-trifexis{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(168 173 0 / var(--tw-bg-opacity));
}
.bg-varenzin_blue{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 86 184 / var(--tw-bg-opacity));
}
.bg-varenzin_purple{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(106 72 142 / var(--tw-bg-opacity));
}
.bg-varenzin_red{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(188 34 81 / var(--tw-bg-opacity));
}
.bg-vet-purple{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(56 24 162 / var(--tw-bg-opacity));
}
.bg-white{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-white-transparent{
	background-color:
		rgba(255,255,255,0.95);
}
.bg-white\/70{
	background-color:
		rgb(255 255 255 / 0.7);
}
.bg-zenrelia_accent{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(192 210 154 / var(--tw-bg-opacity));
}
.bg-zenrelia_contrast{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(166 140 250 / var(--tw-bg-opacity));
}
.bg-zenrelia_primary{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(33 84 71 / var(--tw-bg-opacity));
}
.bg-zenrelia_secondary{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(145 204 18 / var(--tw-bg-opacity));
}
.bg-zenrelia_sku1{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(97 133 130 / var(--tw-bg-opacity));
}
.bg-zenrelia_sku2{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(135 82 199 / var(--tw-bg-opacity));
}
.bg-zenrelia_sku3{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 169 158 / var(--tw-bg-opacity));
}
.bg-zenrelia_sku4{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(198 181 255 / var(--tw-bg-opacity));
}
.bg-zolvix_orange{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 95 31 / var(--tw-bg-opacity));
}
.bg-opacity-20{
	--tw-bg-opacity:
		0.2;
}
.bg-opacity-5{
	--tw-bg-opacity:
		0.05;
}
.bg-opacity-50{
	--tw-bg-opacity:
		0.5;
}
.bg-opacity-75{
	--tw-bg-opacity:
		0.75;
}
.bg-opacity-\[0\.34\]{
	--tw-bg-opacity:
		0.34;
}
.bg-none{
	background-image:
		none;
}
.bg-cover{
	background-size:
		cover;
}
.bg-bottom{
	background-position:
		bottom;
}
.bg-center{
	background-position:
		center;
}
.bg-top{
	background-position:
		top;
}
.bg-no-repeat{
	background-repeat:
		no-repeat;
}
.\!fill-mid-grey{
	fill:
		#999999 !important;
}
.\!fill-pet-midnight{
	fill:
		#021546 !important;
}
.fill-\[\#ea0101\]{
	fill:
		#ea0101;
}
.fill-black{
	fill:
		#000;
}
.fill-blue-500{
	fill:
		#0072CE;
}
.fill-current{
	fill:
		currentColor;
}
.fill-white{
	fill:
		#FFF;
}
.stroke-current{
	stroke:
		currentColor;
}
.object-contain{
	-o-object-fit:
		contain;
	   object-fit:
		contain;
}
.object-cover{
	-o-object-fit:
		cover;
	   object-fit:
		cover;
}
.object-bottom{
	-o-object-position:
		bottom;
	   object-position:
		bottom;
}
.object-center{
	-o-object-position:
		center;
	   object-position:
		center;
}
.object-left{
	-o-object-position:
		left;
	   object-position:
		left;
}
.object-top{
	-o-object-position:
		top;
	   object-position:
		top;
}
.\!p-0{
	padding:
		0px !important;
}
.\!p-2{
	padding:
		0.5rem !important;
}
.p-0{
	padding:
		0px;
}
.p-1{
	padding:
		0.25rem;
}
.p-10{
	padding:
		2.5rem;
}
.p-12{
	padding:
		3rem;
}
.p-16{
	padding:
		4rem;
}
.p-2{
	padding:
		0.5rem;
}
.p-2\.5{
	padding:
		0.625rem;
}
.p-20{
	padding:
		5rem;
}
.p-24{
	padding:
		6rem;
}
.p-3{
	padding:
		0.75rem;
}
.p-4{
	padding:
		1rem;
}
.p-5{
	padding:
		1.25rem;
}
.p-6{
	padding:
		1.5rem;
}
.p-7{
	padding:
		1.75rem;
}
.p-8{
	padding:
		2rem;
}
.\!px-12{
	padding-left:
		3rem !important;
	padding-right:
		3rem !important;
}
.\!px-6{
	padding-left:
		1.5rem !important;
	padding-right:
		1.5rem !important;
}
.\!py-2{
	padding-top:
		0.5rem !important;
	padding-bottom:
		0.5rem !important;
}
.\!py-\[4px\]{
	padding-top:
		4px !important;
	padding-bottom:
		4px !important;
}
.px-0{
	padding-left:
		0px;
	padding-right:
		0px;
}
.px-1{
	padding-left:
		0.25rem;
	padding-right:
		0.25rem;
}
.px-10{
	padding-left:
		2.5rem;
	padding-right:
		2.5rem;
}
.px-12{
	padding-left:
		3rem;
	padding-right:
		3rem;
}
.px-16{
	padding-left:
		4rem;
	padding-right:
		4rem;
}
.px-2{
	padding-left:
		0.5rem;
	padding-right:
		0.5rem;
}
.px-3{
	padding-left:
		0.75rem;
	padding-right:
		0.75rem;
}
.px-4{
	padding-left:
		1rem;
	padding-right:
		1rem;
}
.px-5{
	padding-left:
		1.25rem;
	padding-right:
		1.25rem;
}
.px-6{
	padding-left:
		1.5rem;
	padding-right:
		1.5rem;
}
.px-7{
	padding-left:
		1.75rem;
	padding-right:
		1.75rem;
}
.px-8{
	padding-left:
		2rem;
	padding-right:
		2rem;
}
.py-0{
	padding-top:
		0px;
	padding-bottom:
		0px;
}
.py-0\.5{
	padding-top:
		0.125rem;
	padding-bottom:
		0.125rem;
}
.py-1{
	padding-top:
		0.25rem;
	padding-bottom:
		0.25rem;
}
.py-10{
	padding-top:
		2.5rem;
	padding-bottom:
		2.5rem;
}
.py-12{
	padding-top:
		3rem;
	padding-bottom:
		3rem;
}
.py-16{
	padding-top:
		4rem;
	padding-bottom:
		4rem;
}
.py-2{
	padding-top:
		0.5rem;
	padding-bottom:
		0.5rem;
}
.py-20{
	padding-top:
		5rem;
	padding-bottom:
		5rem;
}
.py-24{
	padding-top:
		6rem;
	padding-bottom:
		6rem;
}
.py-3{
	padding-top:
		0.75rem;
	padding-bottom:
		0.75rem;
}
.py-4{
	padding-top:
		1rem;
	padding-bottom:
		1rem;
}
.py-5{
	padding-top:
		1.25rem;
	padding-bottom:
		1.25rem;
}
.py-6{
	padding-top:
		1.5rem;
	padding-bottom:
		1.5rem;
}
.py-8{
	padding-top:
		2rem;
	padding-bottom:
		2rem;
}
.py-\[14px\]{
	padding-top:
		14px;
	padding-bottom:
		14px;
}
.py-\[38px\]{
	padding-top:
		38px;
	padding-bottom:
		38px;
}
.py-\[42px\]{
	padding-top:
		42px;
	padding-bottom:
		42px;
}
.\!pt-1{
	padding-top:
		0.25rem !important;
}
.pb-0{
	padding-bottom:
		0px;
}
.pb-1{
	padding-bottom:
		0.25rem;
}
.pb-1\.5{
	padding-bottom:
		0.375rem;
}
.pb-10{
	padding-bottom:
		2.5rem;
}
.pb-12{
	padding-bottom:
		3rem;
}
.pb-14{
	padding-bottom:
		3.5rem;
}
.pb-16{
	padding-bottom:
		4rem;
}
.pb-2{
	padding-bottom:
		0.5rem;
}
.pb-2\.5{
	padding-bottom:
		0.625rem;
}
.pb-20{
	padding-bottom:
		5rem;
}
.pb-24{
	padding-bottom:
		6rem;
}
.pb-3{
	padding-bottom:
		0.75rem;
}
.pb-4{
	padding-bottom:
		1rem;
}
.pb-5{
	padding-bottom:
		1.25rem;
}
.pb-52{
	padding-bottom:
		13rem;
}
.pb-6{
	padding-bottom:
		1.5rem;
}
.pb-8{
	padding-bottom:
		2rem;
}
.pb-\[100\%\]{
	padding-bottom:
		100%;
}
.pb-\[15px\]{
	padding-bottom:
		15px;
}
.pb-\[30px\]{
	padding-bottom:
		30px;
}
.pl-0{
	padding-left:
		0px;
}
.pl-1{
	padding-left:
		0.25rem;
}
.pl-10{
	padding-left:
		2.5rem;
}
.pl-12{
	padding-left:
		3rem;
}
.pl-2{
	padding-left:
		0.5rem;
}
.pl-3{
	padding-left:
		0.75rem;
}
.pl-4{
	padding-left:
		1rem;
}
.pl-5{
	padding-left:
		1.25rem;
}
.pl-6{
	padding-left:
		1.5rem;
}
.pl-8{
	padding-left:
		2rem;
}
.pl-\[3\.25rem\]{
	padding-left:
		3.25rem;
}
.pl-\[7\.25rem\]{
	padding-left:
		7.25rem;
}
.pr-12{
	padding-right:
		3rem;
}
.pr-16{
	padding-right:
		4rem;
}
.pr-2{
	padding-right:
		0.5rem;
}
.pr-3{
	padding-right:
		0.75rem;
}
.pr-4{
	padding-right:
		1rem;
}
.pr-5{
	padding-right:
		1.25rem;
}
.pr-6{
	padding-right:
		1.5rem;
}
.pr-8{
	padding-right:
		2rem;
}
.pr-\[3\.25rem\]{
	padding-right:
		3.25rem;
}
.pr-\[7\.25rem\]{
	padding-right:
		7.25rem;
}
.pt-0{
	padding-top:
		0px;
}
.pt-1{
	padding-top:
		0.25rem;
}
.pt-10{
	padding-top:
		2.5rem;
}
.pt-12{
	padding-top:
		3rem;
}
.pt-16{
	padding-top:
		4rem;
}
.pt-2{
	padding-top:
		0.5rem;
}
.pt-20{
	padding-top:
		5rem;
}
.pt-3{
	padding-top:
		0.75rem;
}
.pt-4{
	padding-top:
		1rem;
}
.pt-5{
	padding-top:
		1.25rem;
}
.pt-6{
	padding-top:
		1.5rem;
}
.pt-8{
	padding-top:
		2rem;
}
.pt-\[15px\]{
	padding-top:
		15px;
}
.pt-\[20\%\]{
	padding-top:
		20%;
}
.pt-\[30px\]{
	padding-top:
		30px;
}
.pt-\[52\%\]{
	padding-top:
		52%;
}
.text-left{
	text-align:
		left;
}
.text-center{
	text-align:
		center;
}
.text-right{
	text-align:
		right;
}
.text-justify{
	text-align:
		justify;
}
.align-top{
	vertical-align:
		top;
}
.align-middle{
	vertical-align:
		middle;
}
.align-bottom{
	vertical-align:
		bottom;
}
.\!text-sm{
	font-size:
		0.875rem !important;
	line-height:
		1.25rem !important;
}
.\!text-xs{
	font-size:
		0.75rem !important;
	line-height:
		1rem !important;
}
.text-2xl{
	font-size:
		1.5rem;
	line-height:
		2rem;
}
.text-3xl{
	font-size:
		1.875rem;
	line-height:
		2.25rem;
}
.text-4xl{
	font-size:
		2.25rem;
	line-height:
		2.5rem;
}
.text-5xl{
	font-size:
		3rem;
	line-height:
		1;
}
.text-7xl{
	font-size:
		4.5rem;
	line-height:
		1;
}
.text-\[16px\]{
	font-size:
		16px;
}
.text-base{
	font-size:
		1rem;
	line-height:
		1.5rem;
}
.text-lg{
	font-size:
		1.125rem;
	line-height:
		1.75rem;
}
.text-sm{
	font-size:
		0.875rem;
	line-height:
		1.25rem;
}
.text-xl{
	font-size:
		1.25rem;
	line-height:
		1.75rem;
}
.text-xs{
	font-size:
		0.75rem;
	line-height:
		1rem;
}
.\!font-normal{
	font-weight:
		400 !important;
}
.font-bold{
	font-weight:
		700;
}
.font-extrabold{
	font-weight:
		800;
}
.font-light{
	font-weight:
		300;
}
.font-medium{
	font-weight:
		500;
}
.font-normal{
	font-weight:
		400;
}
.font-semibold{
	font-weight:
		600;
}
.uppercase{
	text-transform:
		uppercase;
}
.lowercase{
	text-transform:
		lowercase;
}
.capitalize{
	text-transform:
		capitalize;
}
.italic{
	font-style:
		italic;
}
.not-italic{
	font-style:
		normal;
}
.leading-5{
	line-height:
		1.25rem;
}
.leading-6{
	line-height:
		1.5rem;
}
.leading-\[0\]{
	line-height:
		0;
}
.leading-\[1\.18\]{
	line-height:
		1.18;
}
.leading-\[1\.28\]{
	line-height:
		1.28;
}
.leading-none{
	line-height:
		1;
}
.leading-normal{
	line-height:
		1.5;
}
.leading-relaxed{
	line-height:
		1.625;
}
.leading-tight{
	line-height:
		1.25;
}
.\!text-green-600{
	--tw-text-opacity:
		1 !important;
	color:
		rgb(31 136 21 / var(--tw-text-opacity)) !important;
}
.\!text-mid-grey{
	--tw-text-opacity:
		1 !important;
	color:
		rgb(153 153 153 / var(--tw-text-opacity)) !important;
}
.\!text-pet-green{
	--tw-text-opacity:
		1 !important;
	color:
		rgb(37 136 33 / var(--tw-text-opacity)) !important;
}
.\!text-pet-primary{
	--tw-text-opacity:
		1 !important;
	color:
		rgb(1 19 71 / var(--tw-text-opacity)) !important;
}
.text-\[\#333\]{
	--tw-text-opacity:
		1;
	color:
		rgb(51 51 51 / var(--tw-text-opacity));
}
.text-\[\#373737\]{
	--tw-text-opacity:
		1;
	color:
		rgb(55 55 55 / var(--tw-text-opacity));
}
.text-\[\#666\]{
	--tw-text-opacity:
		1;
	color:
		rgb(102 102 102 / var(--tw-text-opacity));
}
.text-\[\#999\]{
	--tw-text-opacity:
		1;
	color:
		rgb(153 153 153 / var(--tw-text-opacity));
}
.text-black{
	--tw-text-opacity:
		1;
	color:
		rgb(0 0 0 / var(--tw-text-opacity));
}
.text-blue-400{
	--tw-text-opacity:
		1;
	color:
		rgb(0 127 177 / var(--tw-text-opacity));
}
.text-blue-500{
	--tw-text-opacity:
		1;
	color:
		rgb(0 114 206 / var(--tw-text-opacity));
}
.text-blue-800{
	--tw-text-opacity:
		1;
	color:
		rgb(77 102 120 / var(--tw-text-opacity));
}
.text-blue-900{
	--tw-text-opacity:
		1;
	color:
		rgb(2 37 62 / var(--tw-text-opacity));
}
.text-charcoal{
	--tw-text-opacity:
		1;
	color:
		rgb(51 51 51 / var(--tw-text-opacity));
}
.text-elanco-beige{
	--tw-text-opacity:
		1;
	color:
		rgb(213 196 175 / var(--tw-text-opacity));
}
.text-gray-400{
	--tw-text-opacity:
		1;
	color:
		rgb(211 211 211 / var(--tw-text-opacity));
}
.text-gray-500{
	--tw-text-opacity:
		1;
	color:
		rgb(188 186 186 / var(--tw-text-opacity));
}
.text-gray-600{
	--tw-text-opacity:
		1;
	color:
		rgb(112 112 112 / var(--tw-text-opacity));
}
.text-green-400{
	--tw-text-opacity:
		1;
	color:
		rgb(108 179 62 / var(--tw-text-opacity));
}
.text-green-600{
	--tw-text-opacity:
		1;
	color:
		rgb(31 136 21 / var(--tw-text-opacity));
}
.text-midnight{
	--tw-text-opacity:
		1;
	color:
		rgb(3 51 87 / var(--tw-text-opacity));
}
.text-midnight-blue{
	--tw-text-opacity:
		1;
	color:
		rgb(1 19 71 / var(--tw-text-opacity));
}
.text-pet-green{
	--tw-text-opacity:
		1;
	color:
		rgb(37 136 33 / var(--tw-text-opacity));
}
.text-pet-midnight{
	--tw-text-opacity:
		1;
	color:
		rgb(2 21 70 / var(--tw-text-opacity));
}
.text-pet-primary{
	--tw-text-opacity:
		1;
	color:
		rgb(1 19 71 / var(--tw-text-opacity));
}
.text-primary{
	--tw-text-opacity:
		1;
	color:
		rgb(0 114 206 / var(--tw-text-opacity));
}
.text-primary-dark{
	--tw-text-opacity:
		1;
	color:
		rgb(4 83 141 / var(--tw-text-opacity));
}
.text-red-500{
	--tw-text-opacity:
		1;
	color:
		rgb(234 1 1 / var(--tw-text-opacity));
}
.text-red-600{
	--tw-text-opacity:
		1;
	color:
		rgb(190 2 2 / var(--tw-text-opacity));
}
.text-secondary{
	--tw-text-opacity:
		1;
	color:
		rgb(0 171 156 / var(--tw-text-opacity));
}
.text-theme-highlight{
	--tw-text-opacity:
		1;
	color:
		rgba(var(--color-theme-highlight), var(--tw-text-opacity));
}
.text-theme-main{
	--tw-text-opacity:
		1;
	color:
		rgba(var(--color-theme), var(--tw-text-opacity));
}
.text-white{
	--tw-text-opacity:
		1;
	color:
		rgb(255 255 255 / var(--tw-text-opacity));
}
.underline{
	text-decoration-line:
		underline;
}
.placeholder-black::-moz-placeholder{
	--tw-placeholder-opacity:
		1;
	color:
		rgb(0 0 0 / var(--tw-placeholder-opacity));
}
.placeholder-black::placeholder{
	--tw-placeholder-opacity:
		1;
	color:
		rgb(0 0 0 / var(--tw-placeholder-opacity));
}
.opacity-0{
	opacity:
		0;
}
.opacity-25{
	opacity:
		0.25;
}
.opacity-50{
	opacity:
		0.5;
}
.opacity-70{
	opacity:
		0.7;
}
.opacity-75{
	opacity:
		0.75;
}
.opacity-\[100\%\]{
	opacity:
		100%;
}
.opacity-\[40\%\]{
	opacity:
		40%;
}
.\!shadow-around{
	--tw-shadow:
		0px 0px 13px 0px rgba(0,0,0,0.2) !important;
	--tw-shadow-colored:
		0px 0px 13px 0px var(--tw-shadow-color) !important;
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.shadow{
	--tw-shadow:
		0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	--tw-shadow-colored:
		0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0_10px_15px_-3px_rgb\(0_0_0_\/_10\%\)\2c _0_4px_6px_-4px_rgb\(0_0_0_\/_10\%\)\]{
	--tw-shadow:
		0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -4px rgb(0 0 0 / 10%);
	--tw-shadow-colored:
		0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_0px_5px_DarkGrey\]{
	--tw-shadow:
		0px 0px 5px DarkGrey;
	--tw-shadow-colored:
		0px 0px 5px var(--tw-shadow-color);
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_3px_6px_\#00000029\]{
	--tw-shadow:
		0px 3px 6px #00000029;
	--tw-shadow-colored:
		0px 3px 6px var(--tw-shadow-color);
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-around{
	--tw-shadow:
		0px 0px 13px 0px rgba(0,0,0,0.2);
	--tw-shadow-colored:
		0px 0px 13px 0px var(--tw-shadow-color);
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-inner{
	--tw-shadow:
		inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
	--tw-shadow-colored:
		inset 0 2px 4px 0 var(--tw-shadow-color);
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg{
	--tw-shadow:
		0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	--tw-shadow-colored:
		0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md{
	--tw-shadow:
		0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	--tw-shadow-colored:
		0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-none{
	--tw-shadow:
		0 0 #0000;
	--tw-shadow-colored:
		0 0 #0000;
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm{
	--tw-shadow:
		0 1px 2px 0 rgb(0 0 0 / 0.05);
	--tw-shadow-colored:
		0 1px 2px 0 var(--tw-shadow-color);
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-primary{
	--tw-shadow-color:
		#0072CE;
	--tw-shadow:
		var(--tw-shadow-colored);
}
.shadow-secondary{
	--tw-shadow-color:
		#00AB9C;
	--tw-shadow:
		var(--tw-shadow-colored);
}
.outline-none{
	outline:
		2px solid transparent;
	outline-offset:
		2px;
}
.outline{
	outline-style:
		solid;
}
.outline-1{
	outline-width:
		1px;
}
.outline-offset-2{
	outline-offset:
		2px;
}
.ring{
	--tw-ring-offset-shadow:
		var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow:
		var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow:
		var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.blur{
	--tw-blur:
		blur(8px);
	filter:
		var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale{
	--tw-grayscale:
		grayscale(100%);
	filter:
		var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter{
	filter:
		var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-blur-\[21\.6703px\]{
	--tw-backdrop-blur:
		blur(21.6703px);
	-webkit-backdrop-filter:
		var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	backdrop-filter:
		var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-blur-\[5px\]{
	--tw-backdrop-blur:
		blur(5px);
	-webkit-backdrop-filter:
		var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	backdrop-filter:
		var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.backdrop-filter{
	-webkit-backdrop-filter:
		var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	backdrop-filter:
		var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition{
	transition-property:
		color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
	transition-property:
		color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
	transition-property:
		color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
	transition-timing-function:
		cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration:
		150ms;
}
.transition-all{
	transition-property:
		all;
	transition-timing-function:
		cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration:
		150ms;
}
.transition-colors{
	transition-property:
		color, background-color, border-color, text-decoration-color, fill, stroke;
	transition-timing-function:
		cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration:
		150ms;
}
.transition-transform{
	transition-property:
		transform;
	transition-timing-function:
		cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration:
		150ms;
}
.duration-200{
	transition-duration:
		200ms;
}
.duration-300{
	transition-duration:
		300ms;
}
.duration-500{
	transition-duration:
		500ms;
}
.ease-in-out{
	transition-timing-function:
		cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out{
	transition-timing-function:
		cubic-bezier(0, 0, 0.2, 1);
}
.scrollbar{
	--scrollbar-track:
		initial;
	--scrollbar-thumb:
		initial;
	scrollbar-color:
		var(--scrollbar-thumb) var(--scrollbar-track);
	overflow:
		overlay;
}
.scrollbar.overflow-x-hidden{
	overflow-x:
		hidden;
}
.scrollbar.overflow-y-hidden{
	overflow-y:
		hidden;
}
.scrollbar::-webkit-scrollbar-track{
	background-color:
		var(--scrollbar-track);
}
.scrollbar::-webkit-scrollbar-thumb{
	background-color:
		var(--scrollbar-thumb);
}
.scrollbar{
	scrollbar-width:
		auto;
}
.scrollbar::-webkit-scrollbar{
	width:
		16px;
	height:
		16px;
}
.scrollbar-thin{
	--scrollbar-track:
		initial;
	--scrollbar-thumb:
		initial;
	scrollbar-color:
		var(--scrollbar-thumb) var(--scrollbar-track);
	overflow:
		overlay;
}
.scrollbar-thin.overflow-x-hidden{
	overflow-x:
		hidden;
}
.scrollbar-thin.overflow-y-hidden{
	overflow-y:
		hidden;
}
.scrollbar-thin::-webkit-scrollbar-track{
	background-color:
		var(--scrollbar-track);
}
.scrollbar-thin::-webkit-scrollbar-thumb{
	background-color:
		var(--scrollbar-thumb);
}
.scrollbar-thin{
	scrollbar-width:
		thin;
}
.scrollbar-thin::-webkit-scrollbar{
	width:
		8px;
	height:
		8px;
}
.scrollbar-track-gray-\31 00{
	--scrollbar-track:
		#F5F5F5 !important;
}
.scrollbar-thumb-blue-\35 00{
	--scrollbar-thumb:
		#0072CE !important;
}

html,
body{
	margin:
		0px;
	padding:
		0px;
	font-family:
		-apple-system,
		BlinkMacSystemFont,
		Segoe UI,
		Roboto,
		Oxygen,
		Ubuntu,
		Cantarell,
		Fira Sans,
		Droid Sans,
		Helvetica Neue,
		sans-serif;
}

#__next{
	display:
		flex;
	min-height:
		100vh;
	flex-direction:
		column;
}

a {
	color: inherit;
	text-decoration: none;
}

*{
	box-sizing:
		border-box;
}

.faq-content a:not(.btn){
	font-weight:
		700 !important;
	--tw-text-opacity:
		1 !important;
	color:
		rgb(0 114 206 / var(--tw-text-opacity)) !important;
	text-decoration-line:
		underline !important;
}

.faq-content a:not(.btn):hover{
	--tw-text-opacity:
		1 !important;
	color:
		rgb(4 83 141 / var(--tw-text-opacity)) !important;
}

.faq-content a:not(.btn):active{
	--tw-text-opacity:
		1 !important;
	color:
		rgb(0 171 156 / var(--tw-text-opacity)) !important;
}

.preview-mode{
	margin-top:
		2.5rem;
}

.preview-mode .sticky-outer-wrapper.active > .sticky-inner-wrapper{
	top:
		2.5rem !important;
}

/* Override default white text color for these brands */
.brandtext-secondary,
.brandtext-interceptor,
.brandtext-trifexis,
.brandtext-experior,
.brandtext-adtab_white,
.brandtext-adtab_yellow,
.brandtext-prefender{
	--tw-text-opacity:
		1;
	color:
		rgb(0 0 0 / var(--tw-text-opacity));
}

/* PET brand date input fields */

[type='date']::-webkit-inner-spin-button,
[type='date']::-webkit-calendar-picker-indicator {
	opacity: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	left: -20px;
}

/* PET brand rich text fields */

.content-rich-text li {
	list-style-type: disc;
}
.content-rich-text h2 {
	margin-bottom: 12px;
	margin-left: -16px;
}

/* React Schema form generic styles */
.rjsf-form .form-group {
	margin: 10px 0;
}

.rjsf-form .form-control {
	border: 1.5px solid #0072ce;
}

.rjsf-form .control-label {
	font-weight: bold;
	font-size: 12px;
	margin-bottom: 5px;
}

.rjsf-form select {
	background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 448 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z'%3E%3C/path%3E%3C/svg%3E");
	background-repeat: no-repeat, repeat;
	background-position: right 1rem center;
	background-size: 18px 16px;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.rjsf-form .error-detail {
	list-style: none;
	padding-left: 0;
	margin: 5px 0;
}

.rjsf-form .text-danger,
.rjsf-form-error {
	color: #ff0033;
	font-weight: 500;
	margin: 5px 0;
}

.rjsf-form-tabs div {
	padding: 0;
}

.rjsf-form-tabs .content {
	/* Overriding tab styles */
	box-shadow: none !important;
}

.rjsf-form-tab {
	padding: 15px;
	/* Overriding tab styles */
	box-shadow: none !important;
}

.bg-category-behaviour {
	background: #d43900;
}
.bg-category-new_pets {
	background: #bd2ca2;
}
.bg-category-lifestyle {
	background: #b50000;
}
.bg-category-health_and_care {
	background: #138187;
}
.bg-category-parasites {
	background: #3a76bb;
}

/* Loading Spinner */

.lds-spinner{
	position:
		relative;
	display:
		inline-block;
	height:
		5rem;
	width:
		5rem;
	color: official;
}
.lds-spinner div {
	transform-origin: 40px 40px;
	animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after{
	position:
		absolute;
	left:
		37px;
	top:
		3px;
	display:
		block;
	height:
		18px;
	width:
		6px;
	border-radius:
		20%;
	--tw-content:
		'';
	content:
		var(--tw-content);
}
.lds-spinner.blue div:after{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 114 206 / var(--tw-bg-opacity));
}
.lds-spinner.green div:after{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(31 136 21 / var(--tw-bg-opacity));
}
.lds-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}
@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@media (min-width: 1280px){
	.modal-open{
		overflow:
		hidden;
	}
}

/* CDC */

.gigya-screen{
	max-width:
		100%;
	padding:
		0px !important;
}

/* Account Dropdown  */

.account-dropdown{
	position:
		relative;
	display:
		none;
}

@media (min-width: 1024px){
	.account-dropdown{
		display:
		block;
	}
}
.account-dropdown button{
	display:
		flex;
	align-items:
		center;
}
.account-dropdown button > :not([hidden]) ~ :not([hidden]){
	--tw-space-x-reverse:
		0;
	margin-right:
		calc(0.25rem * var(--tw-space-x-reverse));
	margin-left:
		calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.account-dropdown button{
	padding-left:
		0.5rem;
	padding-right:
		0.5rem;
}
.account-dropdown button:after{
	display:
		inline-block;
	height:
		10px;
	width:
		1.25rem;
	background-position:
		center;
	background-repeat:
		no-repeat;
	--tw-content:
		'';
	content:
		var(--tw-content);
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4zMzMiIGhlaWdodD0iNi42NjciPjxwYXRoIGQ9Im0wIDAgNi42NjcgNi42NjdMMTMuMzMzIDBaIiBmaWxsPSIjMDIyNTNlIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=);
}
.account-dropdown button:hover + div{
	display:
		flex;
}
.account-dropdown .nav-link-container{
	position:
		absolute;
	right:
		0px;
	display:
		none;
	width:
		180px;
	flex-direction:
		column;
	border-radius:
		0.375rem;
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 255 255 / var(--tw-bg-opacity));
	--tw-drop-shadow:
		drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
	filter:
		var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
	transition-property:
		all;
	transition-duration:
		200ms;
	transition-timing-function:
		cubic-bezier(0.4, 0, 0.2, 1);
}
.account-dropdown .nav-link-container:hover{
	display:
		flex;
}
.account-dropdown .nav-link-container ul{
	border-radius:
		0.375rem;
	border-width:
		1px;
	--tw-border-opacity:
		1;
	border-color:
		rgb(237 237 237 / var(--tw-border-opacity));
}
.account-dropdown .nav-link-container ul li{
	border-bottom-width:
		1px;
	padding-left:
		1rem;
	padding-right:
		1rem;
}
.account-dropdown .nav-link-container ul li:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(245 245 245 / var(--tw-bg-opacity));
}
.account-dropdown .nav-link-container ul li:last-child{
	border-bottom-left-radius:
		0.375rem;
	border-bottom-right-radius:
		0.375rem;
	border-width:
		0px;
}

.shadow-primary,
.shadow-secondary {
	box-shadow: none;
}

/* Any styling that should be applied to default elements e.g. output of a RTE */
.rich-content h1,
.rich-content h2,
.rich-content h3,
.rich-content h4,
.rich-content h5,
.rich-content p,
.rich-content ul,
.rich-content ol{
	margin-bottom:
		1rem;
}

.rich-content .table-container{
	margin-left:
		auto;
	margin-right:
		auto;
}

.rich-content table{
	border-collapse:
		separate;
	text-align:
		center;
	--tw-shadow-color:
		#00AB9C;
	--tw-shadow:
		var(--tw-shadow-colored);
	box-shadow: none;
}

.rich-content table ul{
	list-style-position:
		inside;
}

.rich-content thead tr{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(128 146 158 / var(--tw-bg-opacity));
}

.rich-content thead th{
	position:
		sticky;
	top:
		0px;
}

@media (min-width: 768px){
	.rich-content thead th{
		position:
		static;
	}
}

.rich-content img{
	margin-left:
		auto;
	margin-right:
		auto;
}

.rich-content tbody tr:nth-child(odd){
	--tw-bg-opacity:
		1;
	background-color:
		rgb(237 237 237 / var(--tw-bg-opacity));
}

.rich-content tbody tr:nth-child(even){
	--tw-bg-opacity:
		1;
	background-color:
		rgb(245 245 245 / var(--tw-bg-opacity));
}

.rich-content th,
.rich-content td{
	min-width:
		130px;
	border-right-width:
		2px;
	--tw-border-opacity:
		1;
	border-color:
		rgb(255 255 255 / var(--tw-border-opacity));
	background-color:
		inherit;
	padding:
		1rem;
}

.rich-content th:first-child,
.rich-content td:first-child{
	position:
		sticky;
	left:
		0px;
	z-index:
		1;
}

.rich-content th:last-of-type,
.rich-content td:last-of-type{
	border-style:
		none;
}

@media (min-width: 768px){
	.rich-content th,
.rich-content td{
		min-width:
		auto;
	}
	.rich-content th:first-child,
.rich-content td:first-child{
		position:
		static;
	}
}

.rich-content th{
	--tw-text-opacity:
		1;
	color:
		rgb(255 255 255 / var(--tw-text-opacity));
}

.rich-content th:first-child{
	z-index:
		1;
}

.rich-content .remove-margin{
	margin-bottom:
		0px;
}

.rich-content.content--remove-bottom-margin :last-child{
	margin-bottom:
		0px;
}

.rich-content.content--remove-bottom-margin > :last-child{
	margin-bottom:
		0px;
}

.rich-content.content--no-width-restriction{
	width:
		100%;
	max-width:
		none;
}

.rich-content a:not(.btn){
	font-weight:
		700;
	--tw-text-opacity:
		1;
	color:
		rgb(0 114 206 / var(--tw-text-opacity));
	text-decoration-line:
		underline;
}

.rich-content a:not(.btn):hover{
	--tw-text-opacity:
		1;
	color:
		rgb(4 83 141 / var(--tw-text-opacity));
}

.rich-content a:not(.btn):active{
	--tw-text-opacity:
		1;
	color:
		rgb(0 171 156 / var(--tw-text-opacity));
}

.rich-content ul{
	list-style-type:
		disc;
	padding-left:
		1.3rem;
}

.rich-content ol{
	list-style-type:
		decimal;
	padding-left:
		1.3rem;
}

.rich-content ul ul{
	margin-top:
		0.5rem;
	padding-left:
		2rem;
}

.rich-content li{
	margin-bottom:
		0.5rem;
}

@media (min-width: 768px){
	.rich-content .table-container{
		display:
		flex;
		width:
		100%;
		align-items:
		flex-start;
		overflow-x:
		scroll;
	}
}

.rich-content .table-container table{
	--tw-shadow:
		0 0 #0000;
	--tw-shadow-colored:
		0 0 #0000;
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.text-center ul{
	list-style-position:
		inside;
}

/* column card lister for 70-30 size of raw */
@media screen and (min-width: 768px) {
	.thirty .column{
		padding:
		0px;
	}

	.thirty .column .article-blockhellotehrerefasdnfkasnkjdfa{
		padding-left:
		0px;
		padding-right:
		0px;
	}
	.thirty .column .article-card{
		margin:
		0px;
		width:
		100%;
	}
}

.faq-block svg {
	--tw-rotate: 0deg;
}

.text-white-links .rich-content a:not(.btn){
	--tw-text-opacity:
		1;
	color:
		rgb(255 255 255 / var(--tw-text-opacity));
}

.text-black-links .rich-content a:not(.btn){
	--tw-text-opacity:
		1;
	color:
		rgb(0 0 0 / var(--tw-text-opacity));
}

.react-calendar__navigation{
	display:
		flex;
	border-radius:
		9999px;
	--tw-bg-opacity:
		1;
	background-color:
		rgb(208 233 199 / var(--tw-bg-opacity));
	font-weight:
		600;
}

.react-calendar__navigation__label{
	font-size:
		1rem;
	line-height:
		1.5rem;
	font-weight:
		600;
}

.react-calendar__viewContainer{
	margin-top:
		1rem;
}

.react-calendar__tile{
	height:
		3.5rem;
	width:
		3.5rem;
}

@media (min-width: 640px){
	.react-calendar__tile{
		height:
		4rem;
		width:
		4rem;
	}
}

@media (min-width: 768px){
	.react-calendar__tile{
		height:
		3.5rem;
		width:
		3.5rem;
	}
}

@media (min-width: 1024px){
	.react-calendar__tile{
		height:
		4rem;
		width:
		4rem;
	}
}

.react-calendar__tile > abbr{
	position:
		relative;
}

.react-calendar__tile > abbr{
	z-index:
		10;
}

.react-calendar__tile:focus{
	outline:
		2px solid transparent;
	outline-offset:
		2px;
}

.react-calendar__month-view__weekdays{
	margin-top:
		0.75rem;
	padding-top:
		0.25rem;
	text-align:
		center;
	--tw-text-opacity:
		1;
	color:
		rgb(188 186 186 / var(--tw-text-opacity));
}

.react-calendar__month-view__weekdays ul{
	list-style-position:
		inside;
}

.react-calendar__month-view__weekdays abbr {
		text-decoration: none;
	}

.active.has-reminder{
	--tw-text-opacity:
		1;
	color:
		rgb(0 171 156 / var(--tw-text-opacity));
}

.flex-slider .slick-track {
	display: flex;
}

.flex-slider .slick-slide {
	height: inherit;
}

.flex-slider .slick-slide > div {
	height: 100%;
}

:root {
	--thumb-background-color: rgba(255,255,255,0.95);
	--thumb-background-image: url('data:image/svg+xml;utf8,<svg viewbox="0 0 60 60" width="60" height="60" xmlns="http://www.w3.org/2000/svg"><path fill="none" style="stroke: rgb(2, 37, 62);" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M20 20 L10 30 L20 40"/><path fill="none" style="stroke: rgb(2, 37, 62);" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M40 20 L50 30 L40 40"/></svg>');
	--thumb-size: clamp(2em, 10vmin, 4em);
	--thumb-radius: 50%;
	--thumb-border-color: rgb(2, 37, 62);
	--thumb-border-size: 2px;

	--focus-width: var(--thumb-border-size);
	--focus-color: #00AB9C;
}

input[type='range'].image-comparison {
	-webkit-appearance: none;
	-moz-appearance: none;
	     appearance: none;
	width: 100%;
	background: transparent;
}

input[type='range'].image-comparison::-webkit-slider-thumb {
	-webkit-appearance: none;
}

input[type='range'].image-comparison:focus {
	outline: none;
}

input[type='range'].image-comparison::-ms-track {
	width: 100%;
	cursor: pointer;

	background: transparent;
	border-color: transparent;
	color: transparent;
}

input[type='range'].image-comparison::-webkit-slider-thumb {
	background-color: var(--thumb-background-color);
	background-image: var(--thumb-background-image);
	background-size: 90%;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: var(--thumb-radius);
	border: var(--thumb-border-size) var(--thumb-border-color) solid;
	color: var(--thumb-border-color);
	width: var(--thumb-size);
	height: var(--thumb-size);
}

input[type='range'].image-comparison::-moz-range-thumb {
	background-color: var(--thumb-background-color);
	background-image: var(--thumb-background-image);
	background-size: 90%;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: var(--thumb-radius);
	border: var(--thumb-border-size) var(--thumb-border-color) solid;
	color: var(--thumb-border-color);
	width: var(--thumb-size);
	height: var(--thumb-size);
}

input[type='range'].image-comparison::-ms-thumb {
	background-color: var(--thumb-background-color);
	background-image: var(--thumb-background-image);
	background-size: 90%;
	background-position: center center;
	background-repeat: no-repeat;
	border-radius: var(--thumb-radius);
	border: var(--thumb-border-size) var(--thumb-border-color) solid;
	color: var(--thumb-border-color);
	width: var(--thumb-size);
	height: var(--thumb-size);
}

input[type='range'].image-comparison:focus::-webkit-slider-thumb {
	box-shadow: 0px 0px 0px var(--focus-width) var(--focus-color);
}

input[type='range'].image-comparison:focus::-moz-range-thumb {
	box-shadow: 0px 0px 0px var(--focus-width) var(--focus-color);
}

input[type='range'].image-comparison:focus::-ms-range-thumb {
	box-shadow: 0px 0px 0px var(--focus-width) var(--focus-color);
}

.\*\:\*\:\!mb-0 > * > *{
	margin-bottom:
		0px !important;
}

.\*\:w-full > *{
	width:
		100%;
}

.first-letter\:uppercase::first-letter{
	text-transform:
		uppercase;
}

.first-letter\:capitalize::first-letter{
	text-transform:
		capitalize;
}

.placeholder\:text-sm::-moz-placeholder{
	font-size:
		0.875rem;
	line-height:
		1.25rem;
}

.placeholder\:text-sm::placeholder{
	font-size:
		0.875rem;
	line-height:
		1.25rem;
}

.before\:absolute::before{
	content:
		var(--tw-content);
	position:
		absolute;
}

.before\:left-0::before{
	content:
		var(--tw-content);
	left:
		0px;
}

.before\:right-0::before{
	content:
		var(--tw-content);
	right:
		0px;
}

.before\:top-0::before{
	content:
		var(--tw-content);
	top:
		0px;
}

.before\:z-30::before{
	content:
		var(--tw-content);
	z-index:
		30;
}

.before\:-mt-2::before{
	content:
		var(--tw-content);
	margin-top:
		-0.5rem;
}

.before\:ml-2::before{
	content:
		var(--tw-content);
	margin-left:
		0.5rem;
}

.before\:ml-4::before{
	content:
		var(--tw-content);
	margin-left:
		1rem;
}

.before\:mr-2::before{
	content:
		var(--tw-content);
	margin-right:
		0.5rem;
}

.before\:mr-4::before{
	content:
		var(--tw-content);
	margin-right:
		1rem;
}

.before\:mt-6::before{
	content:
		var(--tw-content);
	margin-top:
		1.5rem;
}

.before\:block::before{
	content:
		var(--tw-content);
	display:
		block;
}

.before\:inline-block::before{
	content:
		var(--tw-content);
	display:
		inline-block;
}

.before\:hidden::before{
	content:
		var(--tw-content);
	display:
		none;
}

.before\:h-3::before{
	content:
		var(--tw-content);
	height:
		0.75rem;
}

.before\:h-\[6px\]::before{
	content:
		var(--tw-content);
	height:
		6px;
}

.before\:h-\[9px\]::before{
	content:
		var(--tw-content);
	height:
		9px;
}

.before\:h-full::before{
	content:
		var(--tw-content);
	height:
		100%;
}

.before\:w-2::before{
	content:
		var(--tw-content);
	width:
		0.5rem;
}

.before\:w-3::before{
	content:
		var(--tw-content);
	width:
		0.75rem;
}

.before\:w-\[14px\]::before{
	content:
		var(--tw-content);
	width:
		14px;
}

.before\:w-\[5px\]::before{
	content:
		var(--tw-content);
	width:
		5px;
}

.before\:rotate-180::before{
	content:
		var(--tw-content);
	--tw-rotate:
		180deg;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:\!border::before{
	content:
		var(--tw-content);
	border-width:
		1px !important;
}

.before\:border-2::before{
	content:
		var(--tw-content);
	border-width:
		2px;
}

.before\:border-b-0::before{
	content:
		var(--tw-content);
	border-bottom-width:
		0px;
}

.before\:border-r-0::before{
	content:
		var(--tw-content);
	border-right-width:
		0px;
}

.before\:\!border-light-grey::before{
	content:
		var(--tw-content);
	--tw-border-opacity:
		1 !important;
	border-color:
		rgb(204 204 204 / var(--tw-border-opacity)) !important;
}

.before\:border-red-500::before{
	content:
		var(--tw-content);
	--tw-border-opacity:
		1;
	border-color:
		rgb(234 1 1 / var(--tw-border-opacity));
}

.before\:bg-\[\#eee8df\]::before{
	content:
		var(--tw-content);
	--tw-bg-opacity:
		1;
	background-color:
		rgb(238 232 223 / var(--tw-bg-opacity));
}

.before\:bg-white::before{
	content:
		var(--tw-content);
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 255 255 / var(--tw-bg-opacity));
}

.before\:bg-dropdown::before{
	content:
		var(--tw-content);
	background-image:
		url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy4zMzMiIGhlaWdodD0iNi42NjciIHZpZXdCb3g9IjAgMCAxMy4zMzMgNi42NjciPjxwYXRoIGQ9Ik03LDEwbDYuNjY3LDYuNjY3TDIwLjMzMywxMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03IC0xMCkiIGZpbGw9IiM3MDcwNzAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
}

.before\:bg-no-repeat::before{
	content:
		var(--tw-content);
	background-repeat:
		no-repeat;
}

.before\:align-middle::before{
	content:
		var(--tw-content);
	vertical-align:
		middle;
}

.before\:transition::before{
	content:
		var(--tw-content);
	transition-property:
		color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
	transition-property:
		color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
	transition-property:
		color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
	transition-timing-function:
		cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration:
		150ms;
}

.before\:duration-\[400\]::before{
	content:
		var(--tw-content);
	transition-duration:
		400;
}

.before\:content-\[\"\"\]::before{
	--tw-content:
		"";
	content:
		var(--tw-content);
}

.before\:content-\[\'\'\]::before{
	--tw-content:
		'';
	content:
		var(--tw-content);
}

.after\:absolute::after{
	content:
		var(--tw-content);
	position:
		absolute;
}

.after\:\!left-\[9px\]::after{
	content:
		var(--tw-content);
	left:
		9px !important;
}

.after\:\!top-\[9px\]::after{
	content:
		var(--tw-content);
	top:
		9px !important;
}

.after\:left-4::after{
	content:
		var(--tw-content);
	left:
		1rem;
}

.after\:right-0::after{
	content:
		var(--tw-content);
	right:
		0px;
}

.after\:top-0::after{
	content:
		var(--tw-content);
	top:
		0px;
}

.after\:block::after{
	content:
		var(--tw-content);
	display:
		block;
}

.after\:\!h-\[14px\]::after{
	content:
		var(--tw-content);
	height:
		14px !important;
}

.after\:h-full::after{
	content:
		var(--tw-content);
	height:
		100%;
}

.after\:\!w-\[14px\]::after{
	content:
		var(--tw-content);
	width:
		14px !important;
}

.after\:w-10::after{
	content:
		var(--tw-content);
	width:
		2.5rem;
}

.after\:w-px::after{
	content:
		var(--tw-content);
	width:
		1px;
}

.after\:\!bg-pet-green::after{
	content:
		var(--tw-content);
	--tw-bg-opacity:
		1 !important;
	background-color:
		rgb(37 136 33 / var(--tw-bg-opacity)) !important;
}

.after\:bg-primary::after{
	content:
		var(--tw-content);
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 114 206 / var(--tw-bg-opacity));
}

.after\:content-\[\"\"\]::after{
	--tw-content:
		"";
	content:
		var(--tw-content);
}

.after\:content-\[\'\'\]::after{
	--tw-content:
		'';
	content:
		var(--tw-content);
}

.last\:border-r-0:last-child{
	border-right-width:
		0px;
}

.last\:border-none:last-child{
	border-style:
		none;
}

.last\:pb-0:last-child{
	padding-bottom:
		0px;
}

.last\:pr-4:last-child{
	padding-right:
		1rem;
}

.odd\:border-t:nth-child(odd){
	border-top-width:
		1px;
}

.odd\:bg-gray-100:nth-child(odd){
	--tw-bg-opacity:
		1;
	background-color:
		rgb(245 245 245 / var(--tw-bg-opacity));
}

.odd\:pt-4:nth-child(odd){
	padding-top:
		1rem;
}

.even\:bg-transparent:nth-child(even){
	background-color:
		rgba(0, 0, 0, 0);
}

.first-of-type\:pl-0:first-of-type{
	padding-left:
		0px;
}

.last-of-type\:border-0:last-of-type{
	border-width:
		0px;
}

.hover\:cursor-pointer:hover{
	cursor:
		pointer;
}

.hover\:border-orange-600:hover{
	--tw-border-opacity:
		1;
	border-color:
		rgb(255 153 0 / var(--tw-border-opacity));
}

.hover\:\!bg-transparent:hover{
	background-color:
		rgba(0, 0, 0, 0) !important;
}

.hover\:\!bg-white:hover{
	--tw-bg-opacity:
		1 !important;
	background-color:
		rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.hover\:bg-\[\#74a30e\]:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(116 163 14 / var(--tw-bg-opacity));
}

.hover\:bg-black:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:bg-blue-400:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 127 177 / var(--tw-bg-opacity));
}

.hover\:bg-blue-800:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(77 102 120 / var(--tw-bg-opacity));
}

.hover\:bg-blue-900:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(2 37 62 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(237 237 237 / var(--tw-bg-opacity));
}

.hover\:bg-gray-300:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(224 224 224 / var(--tw-bg-opacity));
}

.hover\:bg-gray-400:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(211 211 211 / var(--tw-bg-opacity));
}

.hover\:bg-green-400:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(108 179 62 / var(--tw-bg-opacity));
}

.hover\:bg-green-600:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(31 136 21 / var(--tw-bg-opacity));
}

.hover\:bg-orange-500:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(238 125 0 / var(--tw-bg-opacity));
}

.hover\:bg-orange-600:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 153 0 / var(--tw-bg-opacity));
}

.hover\:bg-primary:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 114 206 / var(--tw-bg-opacity));
}

.hover\:bg-primary-dark:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(4 83 141 / var(--tw-bg-opacity));
}

.hover\:bg-red-600:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(190 2 2 / var(--tw-bg-opacity));
}

.hover\:bg-theme-highlight:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgba(var(--color-theme-highlight), var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover{
	background-color:
		rgba(0, 0, 0, 0);
}

.hover\:bg-white:hover{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-blue-500:hover{
	--tw-text-opacity:
		1;
	color:
		rgb(0 114 206 / var(--tw-text-opacity));
}

.hover\:text-blue-800:hover{
	--tw-text-opacity:
		1;
	color:
		rgb(77 102 120 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover{
	--tw-text-opacity:
		1;
	color:
		rgb(112 112 112 / var(--tw-text-opacity));
}

.hover\:text-green-600:hover{
	--tw-text-opacity:
		1;
	color:
		rgb(31 136 21 / var(--tw-text-opacity));
}

.hover\:text-primary:hover{
	--tw-text-opacity:
		1;
	color:
		rgb(0 114 206 / var(--tw-text-opacity));
}

.hover\:text-primary-dark:hover{
	--tw-text-opacity:
		1;
	color:
		rgb(4 83 141 / var(--tw-text-opacity));
}

.hover\:text-secondary:hover{
	--tw-text-opacity:
		1;
	color:
		rgb(0 171 156 / var(--tw-text-opacity));
}

.hover\:text-theme-main:hover{
	--tw-text-opacity:
		1;
	color:
		rgba(var(--color-theme), var(--tw-text-opacity));
}

.hover\:text-white:hover{
	--tw-text-opacity:
		1;
	color:
		rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover{
	text-decoration-line:
		underline;
}

.hover\:no-underline:hover{
	text-decoration-line:
		none;
}

.hover\:opacity-100:hover{
	opacity:
		1;
}

.hover\:grayscale:hover{
	--tw-grayscale:
		grayscale(100%);
	filter:
		var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hover\:grayscale-0:hover{
	--tw-grayscale:
		grayscale(0);
	filter:
		var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.focus\:bg-gray-200:focus{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(237 237 237 / var(--tw-bg-opacity));
}

.focus\:bg-gray-300:focus{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(224 224 224 / var(--tw-bg-opacity));
}

.focus\:bg-gray-400:focus{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(211 211 211 / var(--tw-bg-opacity));
}

.focus\:bg-primary:focus{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 114 206 / var(--tw-bg-opacity));
}

.focus\:bg-primary-dark:focus{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(4 83 141 / var(--tw-bg-opacity));
}

.focus\:bg-secondary:focus{
	background-color:
		var(--color-bg-secondary);
}

.focus\:text-secondary:focus{
	--tw-text-opacity:
		1;
	color:
		rgb(0 171 156 / var(--tw-text-opacity));
}

.focus\:text-white:focus{
	--tw-text-opacity:
		1;
	color:
		rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:underline:focus{
	text-decoration-line:
		underline;
}

.focus\:no-underline:focus{
	text-decoration-line:
		none;
}

.focus\:outline-none:focus{
	outline:
		2px solid transparent;
	outline-offset:
		2px;
}

.active\:\!bg-transparent:active{
	background-color:
		rgba(0, 0, 0, 0) !important;
}

.group:hover .group-hover\:bg-secondary{
	background-color:
		var(--color-bg-secondary);
}

.group:hover .group-hover\:text-pet-green{
	--tw-text-opacity:
		1;
	color:
		rgb(37 136 33 / var(--tw-text-opacity));
}

.peer:checked ~ .peer-checked\:border-primary{
	--tw-border-opacity:
		1;
	border-color:
		rgb(0 114 206 / var(--tw-border-opacity));
}

.peer:checked ~ .peer-checked\:bg-primary{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(0 114 206 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:text-white{
	--tw-text-opacity:
		1;
	color:
		rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 640px){
	.sm\:-right-3{
		right:
		-0.75rem;
	}
	.sm\:-top-6{
		top:
		-1.5rem;
	}
	.sm\:m-3{
		margin:
		0.75rem;
	}
	.sm\:mx-2{
		margin-left:
		0.5rem;
		margin-right:
		0.5rem;
	}
	.sm\:mx-5{
		margin-left:
		1.25rem;
		margin-right:
		1.25rem;
	}
	.sm\:mb-1{
		margin-bottom:
		0.25rem;
	}
	.sm\:ml-0{
		margin-left:
		0px;
	}
	.sm\:ml-4{
		margin-left:
		1rem;
	}
	.sm\:mr-4{
		margin-right:
		1rem;
	}
	.sm\:mr-5{
		margin-right:
		1.25rem;
	}
	.sm\:mr-6{
		margin-right:
		1.5rem;
	}
	.sm\:mt-0{
		margin-top:
		0px;
	}
	.sm\:mt-12{
		margin-top:
		3rem;
	}
	.sm\:flex{
		display:
		flex;
	}
	.sm\:h-12{
		height:
		3rem;
	}
	.sm\:h-64{
		height:
		16rem;
	}
	.sm\:h-80{
		height:
		20rem;
	}
	.sm\:h-\[700px\]{
		height:
		700px;
	}
	.sm\:w-1\/2{
		width:
		50%;
	}
	.sm\:w-1\/3{
		width:
		33.333333%;
	}
	.sm\:w-10\/12{
		width:
		83.333333%;
	}
	.sm\:w-12{
		width:
		3rem;
	}
	.sm\:w-3\/4{
		width:
		75%;
	}
	.sm\:w-auto{
		width:
		auto;
	}
	.sm\:w-full{
		width:
		100%;
	}
	.sm\:max-w-screen-md{
		max-width:
		768px;
	}
	.sm\:grid-cols-1{
		grid-template-columns:
		repeat(1, minmax(0, 1fr));
	}
	.sm\:grid-cols-2{
		grid-template-columns:
		repeat(2, minmax(0, 1fr));
	}
	.sm\:flex-row{
		flex-direction:
		row;
	}
	.sm\:flex-wrap{
		flex-wrap:
		wrap;
	}
	.sm\:items-start{
		align-items:
		flex-start;
	}
	.sm\:items-center{
		align-items:
		center;
	}
	.sm\:justify-start{
		justify-content:
		flex-start;
	}
	.sm\:justify-between{
		justify-content:
		space-between;
	}
	.sm\:justify-around{
		justify-content:
		space-around;
	}
	.sm\:p-6{
		padding:
		1.5rem;
	}
	.sm\:px-0{
		padding-left:
		0px;
		padding-right:
		0px;
	}
	.sm\:px-8{
		padding-left:
		2rem;
		padding-right:
		2rem;
	}
	.sm\:py-4{
		padding-top:
		1rem;
		padding-bottom:
		1rem;
	}
	.sm\:pb-20{
		padding-bottom:
		5rem;
	}
	.sm\:pl-20{
		padding-left:
		5rem;
	}
	.sm\:pr-20{
		padding-right:
		5rem;
	}
	.sm\:pr-8{
		padding-right:
		2rem;
	}
	.sm\:pt-4{
		padding-top:
		1rem;
	}
	.sm\:text-left{
		text-align:
		left;
	}
	.sm\:text-start{
		text-align:
		start;
	}
	.sm\:text-base{
		font-size:
		1rem;
		line-height:
		1.5rem;
	}
}

@media (min-width: 768px){
	.md\:visible{
		visibility:
		visible;
	}
	.md\:invisible{
		visibility:
		hidden;
	}
	.md\:static{
		position:
		static;
	}
	.md\:absolute{
		position:
		absolute;
	}
	.md\:relative{
		position:
		relative;
	}
	.md\:inset-0{
		inset:
		0px;
	}
	.md\:-left-12{
		left:
		-3rem;
	}
	.md\:-top-12{
		top:
		-3rem;
	}
	.md\:bottom-0{
		bottom:
		0px;
	}
	.md\:bottom-16{
		bottom:
		4rem;
	}
	.md\:bottom-20{
		bottom:
		5rem;
	}
	.md\:bottom-auto{
		bottom:
		auto;
	}
	.md\:left-0{
		left:
		0px;
	}
	.md\:left-12{
		left:
		3rem;
	}
	.md\:left-auto{
		left:
		auto;
	}
	.md\:right-0{
		right:
		0px;
	}
	.md\:right-5{
		right:
		1.25rem;
	}
	.md\:top-0{
		top:
		0px;
	}
	.md\:top-14{
		top:
		3.5rem;
	}
	.md\:top-2{
		top:
		0.5rem;
	}
	.md\:top-auto{
		top:
		auto;
	}
	.md\:z-10{
		z-index:
		10;
	}
	.md\:z-50{
		z-index:
		50;
	}
	.md\:order-last{
		order:
		9999;
	}
	.md\:m-0{
		margin:
		0px;
	}
	.md\:m-2{
		margin:
		0.5rem;
	}
	.md\:-mx-2{
		margin-left:
		-0.5rem;
		margin-right:
		-0.5rem;
	}
	.md\:-mx-4{
		margin-left:
		-1rem;
		margin-right:
		-1rem;
	}
	.md\:-my-0{
		margin-top:
		-0px;
		margin-bottom:
		-0px;
	}
	.md\:-my-0\.5{
		margin-top:
		-0.125rem;
		margin-bottom:
		-0.125rem;
	}
	.md\:-my-12{
		margin-top:
		-3rem;
		margin-bottom:
		-3rem;
	}
	.md\:-my-16{
		margin-top:
		-4rem;
		margin-bottom:
		-4rem;
	}
	.md\:mx-0{
		margin-left:
		0px;
		margin-right:
		0px;
	}
	.md\:mx-2{
		margin-left:
		0.5rem;
		margin-right:
		0.5rem;
	}
	.md\:mx-4{
		margin-left:
		1rem;
		margin-right:
		1rem;
	}
	.md\:mx-6{
		margin-left:
		1.5rem;
		margin-right:
		1.5rem;
	}
	.md\:mx-8{
		margin-left:
		2rem;
		margin-right:
		2rem;
	}
	.md\:mx-auto{
		margin-left:
		auto;
		margin-right:
		auto;
	}
	.md\:my-0{
		margin-top:
		0px;
		margin-bottom:
		0px;
	}
	.md\:my-0\.5{
		margin-top:
		0.125rem;
		margin-bottom:
		0.125rem;
	}
	.md\:my-12{
		margin-top:
		3rem;
		margin-bottom:
		3rem;
	}
	.md\:my-16{
		margin-top:
		4rem;
		margin-bottom:
		4rem;
	}
	.md\:my-6{
		margin-top:
		1.5rem;
		margin-bottom:
		1.5rem;
	}
	.md\:my-8{
		margin-top:
		2rem;
		margin-bottom:
		2rem;
	}
	.md\:my-auto{
		margin-top:
		auto;
		margin-bottom:
		auto;
	}
	.md\:-mb-0{
		margin-bottom:
		-0px;
	}
	.md\:-mb-0\.5{
		margin-bottom:
		-0.125rem;
	}
	.md\:-mb-12{
		margin-bottom:
		-3rem;
	}
	.md\:-mb-16{
		margin-bottom:
		-4rem;
	}
	.md\:-mb-20{
		margin-bottom:
		-5rem;
	}
	.md\:-mb-48{
		margin-bottom:
		-12rem;
	}
	.md\:-mt-0{
		margin-top:
		-0px;
	}
	.md\:-mt-0\.5{
		margin-top:
		-0.125rem;
	}
	.md\:-mt-12{
		margin-top:
		-3rem;
	}
	.md\:-mt-16{
		margin-top:
		-4rem;
	}
	.md\:-mt-20{
		margin-top:
		-5rem;
	}
	.md\:-mt-44{
		margin-top:
		-11rem;
	}
	.md\:-mt-6{
		margin-top:
		-1.5rem;
	}
	.md\:-mt-\[20\%\]{
		margin-top:
		-20%;
	}
	.md\:mb-0{
		margin-bottom:
		0px;
	}
	.md\:mb-0\.5{
		margin-bottom:
		0.125rem;
	}
	.md\:mb-12{
		margin-bottom:
		3rem;
	}
	.md\:mb-16{
		margin-bottom:
		4rem;
	}
	.md\:mb-4{
		margin-bottom:
		1rem;
	}
	.md\:mb-6{
		margin-bottom:
		1.5rem;
	}
	.md\:mb-8{
		margin-bottom:
		2rem;
	}
	.md\:mb-\[88px\]{
		margin-bottom:
		88px;
	}
	.md\:ml-0{
		margin-left:
		0px;
	}
	.md\:ml-16{
		margin-left:
		4rem;
	}
	.md\:ml-2{
		margin-left:
		0.5rem;
	}
	.md\:ml-4{
		margin-left:
		1rem;
	}
	.md\:ml-5{
		margin-left:
		1.25rem;
	}
	.md\:ml-6{
		margin-left:
		1.5rem;
	}
	.md\:ml-8{
		margin-left:
		2rem;
	}
	.md\:ml-auto{
		margin-left:
		auto;
	}
	.md\:mr-1{
		margin-right:
		0.25rem;
	}
	.md\:mr-10{
		margin-right:
		2.5rem;
	}
	.md\:mr-16{
		margin-right:
		4rem;
	}
	.md\:mr-2{
		margin-right:
		0.5rem;
	}
	.md\:mr-20{
		margin-right:
		5rem;
	}
	.md\:mr-4{
		margin-right:
		1rem;
	}
	.md\:mr-6{
		margin-right:
		1.5rem;
	}
	.md\:mr-8{
		margin-right:
		2rem;
	}
	.md\:mt-0{
		margin-top:
		0px;
	}
	.md\:mt-0\.5{
		margin-top:
		0.125rem;
	}
	.md\:mt-10{
		margin-top:
		2.5rem;
	}
	.md\:mt-12{
		margin-top:
		3rem;
	}
	.md\:mt-16{
		margin-top:
		4rem;
	}
	.md\:mt-20{
		margin-top:
		5rem;
	}
	.md\:mt-4{
		margin-top:
		1rem;
	}
	.md\:mt-6{
		margin-top:
		1.5rem;
	}
	.md\:mt-8{
		margin-top:
		2rem;
	}
	.md\:block{
		display:
		block;
	}
	.md\:inline-block{
		display:
		inline-block;
	}
	.md\:inline{
		display:
		inline;
	}
	.md\:\!flex{
		display:
		flex !important;
	}
	.md\:flex{
		display:
		flex;
	}
	.md\:\!table{
		display:
		table !important;
	}
	.md\:table{
		display:
		table;
	}
	.md\:table-cell{
		display:
		table-cell;
	}
	.md\:table-row-group{
		display:
		table-row-group;
	}
	.md\:table-row{
		display:
		table-row;
	}
	.md\:hidden{
		display:
		none;
	}
	.md\:h-1{
		height:
		0.25rem;
	}
	.md\:h-2{
		height:
		0.5rem;
	}
	.md\:h-24{
		height:
		6rem;
	}
	.md\:h-3\/4{
		height:
		75%;
	}
	.md\:h-44{
		height:
		11rem;
	}
	.md\:h-6{
		height:
		1.5rem;
	}
	.md\:h-\[200px\]{
		height:
		200px;
	}
	.md\:h-\[432px\]{
		height:
		432px;
	}
	.md\:h-\[450px\]{
		height:
		450px;
	}
	.md\:h-\[700px\]{
		height:
		700px;
	}
	.md\:h-auto{
		height:
		auto;
	}
	.md\:h-full{
		height:
		100%;
	}
	.md\:max-h-3\/4{
		max-height:
		75vh;
	}
	.md\:max-h-\[380px\]{
		max-height:
		380px;
	}
	.md\:min-h-\[380px\]{
		min-height:
		380px;
	}
	.md\:min-h-\[400px\]{
		min-height:
		400px;
	}
	.md\:w-1\/2{
		width:
		50%;
	}
	.md\:w-1\/3{
		width:
		33.333333%;
	}
	.md\:w-1\/4{
		width:
		25%;
	}
	.md\:w-1\/5{
		width:
		20%;
	}
	.md\:w-2\/3{
		width:
		66.666667%;
	}
	.md\:w-2\/5{
		width:
		40%;
	}
	.md\:w-24{
		width:
		6rem;
	}
	.md\:w-3\/5{
		width:
		60%;
	}
	.md\:w-4\/5{
		width:
		80%;
	}
	.md\:w-44{
		width:
		11rem;
	}
	.md\:w-5\/12{
		width:
		41.666667%;
	}
	.md\:w-5\/6{
		width:
		83.333333%;
	}
	.md\:w-6{
		width:
		1.5rem;
	}
	.md\:w-64{
		width:
		16rem;
	}
	.md\:w-7\/12{
		width:
		58.333333%;
	}
	.md\:w-9\/10{
		width:
		90%;
	}
	.md\:w-\[160px\]{
		width:
		160px;
	}
	.md\:w-\[200px\]{
		width:
		200px;
	}
	.md\:w-\[575px\]{
		width:
		575px;
	}
	.md\:w-\[700px\]{
		width:
		700px;
	}
	.md\:w-auto{
		width:
		auto;
	}
	.md\:w-full{
		width:
		100%;
	}
	.md\:w-large-card-width{
		width:
		631px;
	}
	.md\:min-w-\[400px\]{
		min-width:
		400px;
	}
	.md\:max-w-6xl{
		max-width:
		72rem;
	}
	.md\:max-w-\[130px\]{
		max-width:
		130px;
	}
	.md\:max-w-\[145px\]{
		max-width:
		145px;
	}
	.md\:max-w-\[150px\]{
		max-width:
		150px;
	}
	.md\:max-w-\[240px\]{
		max-width:
		240px;
	}
	.md\:max-w-\[245px\]{
		max-width:
		245px;
	}
	.md\:max-w-\[30rem\]{
		max-width:
		30rem;
	}
	.md\:max-w-\[373px\]{
		max-width:
		373px;
	}
	.md\:max-w-\[400px\]{
		max-width:
		400px;
	}
	.md\:max-w-\[415px\]{
		max-width:
		415px;
	}
	.md\:max-w-\[420px\]{
		max-width:
		420px;
	}
	.md\:max-w-\[450px\]{
		max-width:
		450px;
	}
	.md\:max-w-\[68rem\]{
		max-width:
		68rem;
	}
	.md\:max-w-\[70\%\]{
		max-width:
		70%;
	}
	.md\:max-w-full{
		max-width:
		100%;
	}
	.md\:max-w-lg{
		max-width:
		32rem;
	}
	.md\:max-w-md{
		max-width:
		28rem;
	}
	.md\:max-w-none{
		max-width:
		none;
	}
	.md\:max-w-sm{
		max-width:
		24rem;
	}
	.md\:max-w-xs{
		max-width:
		20rem;
	}
	.md\:flex-1{
		flex:
		1 1 0%;
	}
	.md\:flex-none{
		flex:
		none;
	}
	.md\:flex-shrink-0{
		flex-shrink:
		0;
	}
	.md\:shrink-0{
		flex-shrink:
		0;
	}
	.md\:basis-1\/2{
		flex-basis:
		50%;
	}
	.md\:basis-1\/3{
		flex-basis:
		33.333333%;
	}
	.md\:basis-6\/12{
		flex-basis:
		50%;
	}
	.md\:-translate-x-4{
		--tw-translate-x:
		-1rem;
		transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
	.md\:translate-y-4{
		--tw-translate-y:
		1rem;
		transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
	.md\:rotate-0{
		--tw-rotate:
		0deg;
		transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
	.md\:-scroll-my-0{
		scroll-margin-top:
		-0px;
		scroll-margin-bottom:
		-0px;
	}
	.md\:-scroll-my-0\.5{
		scroll-margin-top:
		-0.125rem;
		scroll-margin-bottom:
		-0.125rem;
	}
	.md\:-scroll-my-12{
		scroll-margin-top:
		-3rem;
		scroll-margin-bottom:
		-3rem;
	}
	.md\:-scroll-my-16{
		scroll-margin-top:
		-4rem;
		scroll-margin-bottom:
		-4rem;
	}
	.md\:scroll-my-0{
		scroll-margin-top:
		0px;
		scroll-margin-bottom:
		0px;
	}
	.md\:scroll-my-0\.5{
		scroll-margin-top:
		0.125rem;
		scroll-margin-bottom:
		0.125rem;
	}
	.md\:scroll-my-12{
		scroll-margin-top:
		3rem;
		scroll-margin-bottom:
		3rem;
	}
	.md\:scroll-my-16{
		scroll-margin-top:
		4rem;
		scroll-margin-bottom:
		4rem;
	}
	.md\:-scroll-mb-0{
		scroll-margin-bottom:
		-0px;
	}
	.md\:-scroll-mb-0\.5{
		scroll-margin-bottom:
		-0.125rem;
	}
	.md\:-scroll-mb-12{
		scroll-margin-bottom:
		-3rem;
	}
	.md\:-scroll-mb-16{
		scroll-margin-bottom:
		-4rem;
	}
	.md\:-scroll-mt-0{
		scroll-margin-top:
		-0px;
	}
	.md\:-scroll-mt-0\.5{
		scroll-margin-top:
		-0.125rem;
	}
	.md\:-scroll-mt-12{
		scroll-margin-top:
		-3rem;
	}
	.md\:-scroll-mt-16{
		scroll-margin-top:
		-4rem;
	}
	.md\:scroll-mb-0{
		scroll-margin-bottom:
		0px;
	}
	.md\:scroll-mb-0\.5{
		scroll-margin-bottom:
		0.125rem;
	}
	.md\:scroll-mb-12{
		scroll-margin-bottom:
		3rem;
	}
	.md\:scroll-mb-16{
		scroll-margin-bottom:
		4rem;
	}
	.md\:scroll-mt-0{
		scroll-margin-top:
		0px;
	}
	.md\:scroll-mt-0\.5{
		scroll-margin-top:
		0.125rem;
	}
	.md\:scroll-mt-12{
		scroll-margin-top:
		3rem;
	}
	.md\:scroll-mt-16{
		scroll-margin-top:
		4rem;
	}
	.md\:grid-flow-row{
		grid-auto-flow:
		row;
	}
	.md\:grid-cols-1{
		grid-template-columns:
		repeat(1, minmax(0, 1fr));
	}
	.md\:grid-cols-2{
		grid-template-columns:
		repeat(2, minmax(0, 1fr));
	}
	.md\:grid-cols-3{
		grid-template-columns:
		repeat(3, minmax(0, 1fr));
	}
	.md\:flex-row{
		flex-direction:
		row;
	}
	.md\:flex-row-reverse{
		flex-direction:
		row-reverse;
	}
	.md\:flex-col{
		flex-direction:
		column;
	}
	.md\:flex-wrap{
		flex-wrap:
		wrap;
	}
	.md\:flex-nowrap{
		flex-wrap:
		nowrap;
	}
	.md\:items-end{
		align-items:
		flex-end;
	}
	.md\:items-center{
		align-items:
		center;
	}
	.md\:justify-start{
		justify-content:
		flex-start;
	}
	.md\:justify-end{
		justify-content:
		flex-end;
	}
	.md\:justify-center{
		justify-content:
		center;
	}
	.md\:justify-between{
		justify-content:
		space-between;
	}
	.md\:justify-around{
		justify-content:
		space-around;
	}
	.md\:justify-evenly{
		justify-content:
		space-evenly;
	}
	.md\:gap-24{
		gap:
		6rem;
	}
	.md\:gap-x-8{
		-moz-column-gap:
		2rem;
		     column-gap:
		2rem;
	}
	.md\:space-x-2 > :not([hidden]) ~ :not([hidden]){
		--tw-space-x-reverse:
		0;
		margin-right:
		calc(0.5rem * var(--tw-space-x-reverse));
		margin-left:
		calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
	}
	.md\:space-x-4 > :not([hidden]) ~ :not([hidden]){
		--tw-space-x-reverse:
		0;
		margin-right:
		calc(1rem * var(--tw-space-x-reverse));
		margin-left:
		calc(1rem * calc(1 - var(--tw-space-x-reverse)));
	}
	.md\:space-y-0 > :not([hidden]) ~ :not([hidden]){
		--tw-space-y-reverse:
		0;
		margin-top:
		calc(0px * calc(1 - var(--tw-space-y-reverse)));
		margin-bottom:
		calc(0px * var(--tw-space-y-reverse));
	}
	.md\:place-self-center{
		place-self:
		center;
	}
	.md\:overflow-visible{
		overflow:
		visible;
	}
	.md\:overflow-y-auto{
		overflow-y:
		auto;
	}
	.md\:rounded-full{
		border-radius:
		9999px;
	}
	.md\:rounded-lg{
		border-radius:
		0.5rem;
	}
	.md\:rounded-none{
		border-radius:
		0px;
	}
	.md\:rounded-b{
		border-bottom-right-radius:
		0.25rem;
		border-bottom-left-radius:
		0.25rem;
	}
	.md\:rounded-l-lg{
		border-top-left-radius:
		0.5rem;
		border-bottom-left-radius:
		0.5rem;
	}
	.md\:rounded-l-none{
		border-top-left-radius:
		0px;
		border-bottom-left-radius:
		0px;
	}
	.md\:rounded-r-none{
		border-top-right-radius:
		0px;
		border-bottom-right-radius:
		0px;
	}
	.md\:rounded-t-none{
		border-top-left-radius:
		0px;
		border-top-right-radius:
		0px;
	}
	.md\:rounded-tr{
		border-top-right-radius:
		0.25rem;
	}
	.md\:rounded-tr-lg{
		border-top-right-radius:
		0.5rem;
	}
	.md\:border-0{
		border-width:
		0px;
	}
	.md\:border-b{
		border-bottom-width:
		1px;
	}
	.md\:border-l-0{
		border-left-width:
		0px;
	}
	.md\:border-r{
		border-right-width:
		1px;
	}
	.md\:border-r-0{
		border-right-width:
		0px;
	}
	.md\:border-r-2{
		border-right-width:
		2px;
	}
	.md\:border-t{
		border-top-width:
		1px;
	}
	.md\:border-t-0{
		border-top-width:
		0px;
	}
	.md\:border-gray-600{
		--tw-border-opacity:
		1;
		border-color:
		rgb(112 112 112 / var(--tw-border-opacity));
	}
	.md\:bg-blue-900{
		--tw-bg-opacity:
		1;
		background-color:
		rgb(2 37 62 / var(--tw-bg-opacity));
	}
	.md\:bg-gray-100{
		--tw-bg-opacity:
		1;
		background-color:
		rgb(245 245 245 / var(--tw-bg-opacity));
	}
	.md\:bg-transparent{
		background-color:
		rgba(0, 0, 0, 0);
	}
	.md\:bg-white{
		--tw-bg-opacity:
		1;
		background-color:
		rgb(255 255 255 / var(--tw-bg-opacity));
	}
	.md\:object-cover{
		-o-object-fit:
		cover;
		   object-fit:
		cover;
	}
	.md\:p-0{
		padding:
		0px;
	}
	.md\:p-10{
		padding:
		2.5rem;
	}
	.md\:p-12{
		padding:
		3rem;
	}
	.md\:p-16{
		padding:
		4rem;
	}
	.md\:p-24{
		padding:
		6rem;
	}
	.md\:p-4{
		padding:
		1rem;
	}
	.md\:p-5{
		padding:
		1.25rem;
	}
	.md\:p-6{
		padding:
		1.5rem;
	}
	.md\:p-7{
		padding:
		1.75rem;
	}
	.md\:p-8{
		padding:
		2rem;
	}
	.md\:px-0{
		padding-left:
		0px;
		padding-right:
		0px;
	}
	.md\:px-10{
		padding-left:
		2.5rem;
		padding-right:
		2.5rem;
	}
	.md\:px-12{
		padding-left:
		3rem;
		padding-right:
		3rem;
	}
	.md\:px-2{
		padding-left:
		0.5rem;
		padding-right:
		0.5rem;
	}
	.md\:px-4{
		padding-left:
		1rem;
		padding-right:
		1rem;
	}
	.md\:px-5{
		padding-left:
		1.25rem;
		padding-right:
		1.25rem;
	}
	.md\:px-6{
		padding-left:
		1.5rem;
		padding-right:
		1.5rem;
	}
	.md\:px-8{
		padding-left:
		2rem;
		padding-right:
		2rem;
	}
	.md\:py-0{
		padding-top:
		0px;
		padding-bottom:
		0px;
	}
	.md\:py-1{
		padding-top:
		0.25rem;
		padding-bottom:
		0.25rem;
	}
	.md\:py-10{
		padding-top:
		2.5rem;
		padding-bottom:
		2.5rem;
	}
	.md\:py-12{
		padding-top:
		3rem;
		padding-bottom:
		3rem;
	}
	.md\:py-16{
		padding-top:
		4rem;
		padding-bottom:
		4rem;
	}
	.md\:py-2{
		padding-top:
		0.5rem;
		padding-bottom:
		0.5rem;
	}
	.md\:py-20{
		padding-top:
		5rem;
		padding-bottom:
		5rem;
	}
	.md\:py-24{
		padding-top:
		6rem;
		padding-bottom:
		6rem;
	}
	.md\:py-6{
		padding-top:
		1.5rem;
		padding-bottom:
		1.5rem;
	}
	.md\:py-8{
		padding-top:
		2rem;
		padding-bottom:
		2rem;
	}
	.md\:pb-0{
		padding-bottom:
		0px;
	}
	.md\:pb-16{
		padding-bottom:
		4rem;
	}
	.md\:pb-4{
		padding-bottom:
		1rem;
	}
	.md\:pb-8{
		padding-bottom:
		2rem;
	}
	.md\:pl-0{
		padding-left:
		0px;
	}
	.md\:pl-10{
		padding-left:
		2.5rem;
	}
	.md\:pl-2{
		padding-left:
		0.5rem;
	}
	.md\:pl-24{
		padding-left:
		6rem;
	}
	.md\:pl-5{
		padding-left:
		1.25rem;
	}
	.md\:pl-6{
		padding-left:
		1.5rem;
	}
	.md\:pl-8{
		padding-left:
		2rem;
	}
	.md\:pl-\[200px\]{
		padding-left:
		200px;
	}
	.md\:pr-10{
		padding-right:
		2.5rem;
	}
	.md\:pr-12{
		padding-right:
		3rem;
	}
	.md\:pr-16{
		padding-right:
		4rem;
	}
	.md\:pr-4{
		padding-right:
		1rem;
	}
	.md\:pr-5{
		padding-right:
		1.25rem;
	}
	.md\:pr-6{
		padding-right:
		1.5rem;
	}
	.md\:pr-8{
		padding-right:
		2rem;
	}
	.md\:pt-0{
		padding-top:
		0px;
	}
	.md\:pt-16{
		padding-top:
		4rem;
	}
	.md\:pt-6{
		padding-top:
		1.5rem;
	}
	.md\:pt-8{
		padding-top:
		2rem;
	}
	.md\:text-left{
		text-align:
		left;
	}
	.md\:text-right{
		text-align:
		right;
	}
	.md\:text-2xl{
		font-size:
		1.5rem;
		line-height:
		2rem;
	}
	.md\:text-3xl{
		font-size:
		1.875rem;
		line-height:
		2.25rem;
	}
	.md\:text-4xl{
		font-size:
		2.25rem;
		line-height:
		2.5rem;
	}
	.md\:text-base{
		font-size:
		1rem;
		line-height:
		1.5rem;
	}
	.md\:shadow-none{
		--tw-shadow:
		0 0 #0000;
		--tw-shadow-colored:
		0 0 #0000;
		box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	}
	.md\:shadow-secondary{
		--tw-shadow-color:
		#00AB9C;
		--tw-shadow:
		var(--tw-shadow-colored);
	}
	.md\:before\:hidden::before{
		content:
		var(--tw-content);
		display:
		none;
	}
	.after\:md\:left-\[52px\]::after{
		content:
		var(--tw-content);
		left:
		52px;
	}
}

@media (min-width: 1024px){
	.lg\:\!visible{
		visibility:
		visible !important;
	}
	.lg\:\!invisible{
		visibility:
		hidden !important;
	}
	.lg\:static{
		position:
		static;
	}
	.lg\:absolute{
		position:
		absolute;
	}
	.lg\:left-12{
		left:
		3rem;
	}
	.lg\:top-6{
		top:
		1.5rem;
	}
	.lg\:top-8{
		top:
		2rem;
	}
	.lg\:mx-auto{
		margin-left:
		auto;
		margin-right:
		auto;
	}
	.lg\:my-0{
		margin-top:
		0px;
		margin-bottom:
		0px;
	}
	.lg\:-mb-10{
		margin-bottom:
		-2.5rem;
	}
	.lg\:-mt-16{
		margin-top:
		-4rem;
	}
	.lg\:mb-0{
		margin-bottom:
		0px;
	}
	.lg\:mb-10{
		margin-bottom:
		2.5rem;
	}
	.lg\:mb-4{
		margin-bottom:
		1rem;
	}
	.lg\:ml-1{
		margin-left:
		0.25rem;
	}
	.lg\:ml-2{
		margin-left:
		0.5rem;
	}
	.lg\:ml-8{
		margin-left:
		2rem;
	}
	.lg\:mr-10{
		margin-right:
		2.5rem;
	}
	.lg\:mr-4{
		margin-right:
		1rem;
	}
	.lg\:mr-6{
		margin-right:
		1.5rem;
	}
	.lg\:mt-0{
		margin-top:
		0px;
	}
	.lg\:\!block{
		display:
		block !important;
	}
	.lg\:\!flex{
		display:
		flex !important;
	}
	.lg\:flex{
		display:
		flex;
	}
	.lg\:\!hidden{
		display:
		none !important;
	}
	.lg\:hidden{
		display:
		none;
	}
	.lg\:h-\[12\%\]{
		height:
		12%;
	}
	.lg\:h-\[280px\]{
		height:
		280px;
	}
	.lg\:h-auto{
		height:
		auto;
	}
	.lg\:max-h-3\/4{
		max-height:
		75vh;
	}
	.lg\:max-h-screen{
		max-height:
		100vh;
	}
	.lg\:min-h-\[400px\]{
		min-height:
		400px;
	}
	.lg\:w-1\/2{
		width:
		50%;
	}
	.lg\:w-1\/3{
		width:
		33.333333%;
	}
	.lg\:w-1\/4{
		width:
		25%;
	}
	.lg\:w-1\/5{
		width:
		20%;
	}
	.lg\:w-2\/3{
		width:
		66.666667%;
	}
	.lg\:w-2\/4{
		width:
		50%;
	}
	.lg\:w-2\/5{
		width:
		40%;
	}
	.lg\:w-3\/12{
		width:
		25%;
	}
	.lg\:w-3\/5{
		width:
		60%;
	}
	.lg\:w-7\/10{
		width:
		70%;
	}
	.lg\:w-7\/12{
		width:
		58.333333%;
	}
	.lg\:w-80{
		width:
		20rem;
	}
	.lg\:w-\[12\%\]{
		width:
		12%;
	}
	.lg\:w-\[280px\]{
		width:
		280px;
	}
	.lg\:w-\[30\%\]{
		width:
		30%;
	}
	.lg\:w-\[60\%\]{
		width:
		60%;
	}
	.lg\:w-\[70\%\]{
		width:
		70%;
	}
	.lg\:w-full{
		width:
		100%;
	}
	.lg\:w-max{
		width:
		-moz-max-content;
		width:
		max-content;
	}
	.lg\:max-w-\[45\%\]{
		max-width:
		45%;
	}
	.lg\:max-w-\[500px\]{
		max-width:
		500px;
	}
	.lg\:max-w-\[90rem\]{
		max-width:
		90rem;
	}
	.lg\:max-w-lg{
		max-width:
		32rem;
	}
	.lg\:grid-cols-3{
		grid-template-columns:
		repeat(3, minmax(0, 1fr));
	}
	.lg\:grid-cols-4{
		grid-template-columns:
		repeat(4, minmax(0, 1fr));
	}
	.lg\:flex-row{
		flex-direction:
		row;
	}
	.lg\:flex-nowrap{
		flex-wrap:
		nowrap;
	}
	.lg\:items-center{
		align-items:
		center;
	}
	.lg\:justify-start{
		justify-content:
		flex-start;
	}
	.lg\:gap-0{
		gap:
		0px;
	}
	.lg\:gap-3{
		gap:
		0.75rem;
	}
	.lg\:space-x-4 > :not([hidden]) ~ :not([hidden]){
		--tw-space-x-reverse:
		0;
		margin-right:
		calc(1rem * var(--tw-space-x-reverse));
		margin-left:
		calc(1rem * calc(1 - var(--tw-space-x-reverse)));
	}
	.lg\:space-y-0 > :not([hidden]) ~ :not([hidden]){
		--tw-space-y-reverse:
		0;
		margin-top:
		calc(0px * calc(1 - var(--tw-space-y-reverse)));
		margin-bottom:
		calc(0px * var(--tw-space-y-reverse));
	}
	.lg\:overflow-y-auto{
		overflow-y:
		auto;
	}
	.lg\:border-b-0{
		border-bottom-width:
		0px;
	}
	.lg\:border-l{
		border-left-width:
		1px;
	}
	.lg\:bg-transparent{
		background-color:
		rgba(0, 0, 0, 0);
	}
	.lg\:p-10{
		padding:
		2.5rem;
	}
	.lg\:p-4{
		padding:
		1rem;
	}
	.lg\:p-6{
		padding:
		1.5rem;
	}
	.lg\:px-10{
		padding-left:
		2.5rem;
		padding-right:
		2.5rem;
	}
	.lg\:px-14{
		padding-left:
		3.5rem;
		padding-right:
		3.5rem;
	}
	.lg\:px-16{
		padding-left:
		4rem;
		padding-right:
		4rem;
	}
	.lg\:px-5{
		padding-left:
		1.25rem;
		padding-right:
		1.25rem;
	}
	.lg\:px-6{
		padding-left:
		1.5rem;
		padding-right:
		1.5rem;
	}
	.lg\:px-8{
		padding-left:
		2rem;
		padding-right:
		2rem;
	}
	.lg\:py-10{
		padding-top:
		2.5rem;
		padding-bottom:
		2.5rem;
	}
	.lg\:py-12{
		padding-top:
		3rem;
		padding-bottom:
		3rem;
	}
	.lg\:py-16{
		padding-top:
		4rem;
		padding-bottom:
		4rem;
	}
	.lg\:pb-0{
		padding-bottom:
		0px;
	}
	.lg\:pb-10{
		padding-bottom:
		2.5rem;
	}
	.lg\:pl-0{
		padding-left:
		0px;
	}
	.lg\:pl-10{
		padding-left:
		2.5rem;
	}
	.lg\:pl-20{
		padding-left:
		5rem;
	}
	.lg\:pl-6{
		padding-left:
		1.5rem;
	}
	.lg\:pr-0{
		padding-right:
		0px;
	}
	.lg\:pr-20{
		padding-right:
		5rem;
	}
	.lg\:pr-6{
		padding-right:
		1.5rem;
	}
	.lg\:pt-0{
		padding-top:
		0px;
	}
	.lg\:pt-16{
		padding-top:
		4rem;
	}
	.lg\:pt-3{
		padding-top:
		0.75rem;
	}
	.lg\:text-xl{
		font-size:
		1.25rem;
		line-height:
		1.75rem;
	}
	.lg\:text-white{
		--tw-text-opacity:
		1;
		color:
		rgb(255 255 255 / var(--tw-text-opacity));
	}
	.lg\:after\:w-3\/10::after{
		content:
		var(--tw-content);
		width:
		30%;
	}
	.lg\:after\:content-\[\'\'\]::after{
		--tw-content:
		'';
		content:
		var(--tw-content);
	}
}

@media (min-width: 1280px){
	.xl\:fixed{
		position:
		fixed;
	}
	.xl\:left-2\/4{
		left:
		50%;
	}
	.xl\:left-20{
		left:
		5rem;
	}
	.xl\:top-2\/4{
		top:
		50%;
	}
	.xl\:mx-auto{
		margin-left:
		auto;
		margin-right:
		auto;
	}
	.xl\:mb-3{
		margin-bottom:
		0.75rem;
	}
	.xl\:ml-12{
		margin-left:
		3rem;
	}
	.xl\:block{
		display:
		block;
	}
	.xl\:flex{
		display:
		flex;
	}
	.xl\:hidden{
		display:
		none;
	}
	.xl\:max-h-\[264px\]{
		max-height:
		264px;
	}
	.xl\:w-1\/4{
		width:
		25%;
	}
	.xl\:w-12{
		width:
		3rem;
	}
	.xl\:w-2\/5{
		width:
		40%;
	}
	.xl\:w-3\/12{
		width:
		25%;
	}
	.xl\:w-\[calc\(100\%-400px\)\]{
		width:
		calc(100% - 400px);
	}
	.xl\:w-auto{
		width:
		auto;
	}
	.xl\:max-w-5xl{
		max-width:
		64rem;
	}
	.xl\:-translate-x-2\/4{
		--tw-translate-x:
		-50%;
		transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
	.xl\:-translate-y-2\/4{
		--tw-translate-y:
		-50%;
		transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
	.xl\:transform{
		transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
	.xl\:grid-cols-5{
		grid-template-columns:
		repeat(5, minmax(0, 1fr));
	}
	.xl\:overflow-auto{
		overflow:
		auto;
	}
	.xl\:rounded{
		border-radius:
		0.25rem;
	}
	.xl\:rounded-b{
		border-bottom-right-radius:
		0.25rem;
		border-bottom-left-radius:
		0.25rem;
	}
	.xl\:rounded-t{
		border-top-left-radius:
		0.25rem;
		border-top-right-radius:
		0.25rem;
	}
	.xl\:border-l{
		border-left-width:
		1px;
	}
	.xl\:px-0{
		padding-left:
		0px;
		padding-right:
		0px;
	}
	.xl\:px-10{
		padding-left:
		2.5rem;
		padding-right:
		2.5rem;
	}
	.xl\:px-12{
		padding-left:
		3rem;
		padding-right:
		3rem;
	}
	.xl\:px-4{
		padding-left:
		1rem;
		padding-right:
		1rem;
	}
	.xl\:px-6{
		padding-left:
		1.5rem;
		padding-right:
		1.5rem;
	}
	.xl\:pl-24{
		padding-left:
		6rem;
	}
	.xl\:pl-3{
		padding-left:
		0.75rem;
	}
	.xl\:pr-16{
		padding-right:
		4rem;
	}
	.xl\:pr-3{
		padding-right:
		0.75rem;
	}
	.xl\:pt-4{
		padding-top:
		1rem;
	}
	.xl\:text-center{
		text-align:
		center;
	}
	.xl\:text-base{
		font-size:
		1rem;
		line-height:
		1.5rem;
	}
}

@media (min-width: 1536px){
	.\32xl\:h-\[210px\]{
		height:
		210px;
	}
}

@media (min-width: 480px) and (max-width: 640px){
	.xs\:h-72{
		height:
		18rem;
	}
	.xs\:w-1\/2{
		width:
		50%;
	}
}

@media (min-width: 1420px) and (max-width: 1536px){
	.xlg\:left-35{
		left:
		9.8rem !important;
	}
}

@media (prefers-color-scheme: dark){
	.dark\:bg-gray-700{
		--tw-bg-opacity:
		1;
		background-color:
		rgb(211 211 211 / var(--tw-bg-opacity));
	}
	.dark\:bg-highlight{
		background-color:
		var(--color-bg-highlight);
	}
}

.\[\&\>\*\]\:scale-75>*{
	--tw-scale-x:
		.75;
	--tw-scale-y:
		.75;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (min-width: 768px){
	.md\:\[\&\>\*\]\:scale-100>*{
		--tw-scale-x:
		1;
		--tw-scale-y:
		1;
		transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
	}
}

.\[\&\>a\]\:hover\:text-primary-dark:hover>a{
	--tw-text-opacity:
		1;
	color:
		rgb(4 83 141 / var(--tw-text-opacity));
}

.\[\&\>img\]\:mb-0>img{
	margin-bottom:
		0px;
}

.\[\&\>li\]\:relative>li{
	position:
		relative;
}

.\[\&\>li\]\:mb-4>li{
	margin-bottom:
		1rem;
}

.\[\&\>li\]\:overflow-hidden>li{
	overflow:
		hidden;
}

.\[\&\>li\]\:border-b>li{
	border-bottom-width:
		1px;
}

.\[\&\>li\]\:border-gray-500>li{
	--tw-border-opacity:
		1;
	border-color:
		rgb(188 186 186 / var(--tw-border-opacity));
}

.\[\&\>li\]\:bg-white>li{
	--tw-bg-opacity:
		1;
	background-color:
		rgb(255 255 255 / var(--tw-bg-opacity));
}

.\[\&\>li\]\:py-1>li{
	padding-top:
		0.25rem;
	padding-bottom:
		0.25rem;
}

.\[\&\>li\]\:pl-12>li{
	padding-left:
		3rem;
}

.\[\&\>li\]\:before\:absolute>li::before{
	content:
		var(--tw-content);
	position:
		absolute;
}

.\[\&\>li\]\:before\:left-2\.5>li::before{
	content:
		var(--tw-content);
	left:
		0.625rem;
}

.\[\&\>li\]\:before\:top-2\.5>li::before{
	content:
		var(--tw-content);
	top:
		0.625rem;
}

.\[\&\>li\]\:before\:block>li::before{
	content:
		var(--tw-content);
	display:
		block;
}

.\[\&\>li\]\:before\:h-6>li::before{
	content:
		var(--tw-content);
	height:
		1.5rem;
}

.\[\&\>li\]\:before\:w-6>li::before{
	content:
		var(--tw-content);
	width:
		1.5rem;
}

.\[\&\>li\]\:before\:scale-\[200\%\]>li::before{
	content:
		var(--tw-content);
	--tw-scale-x:
		200%;
	--tw-scale-y:
		200%;
	transform:
		translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\>li\]\:before\:bg-no-repeat>li::before{
	content:
		var(--tw-content);
	background-repeat:
		no-repeat;
}

.\[\&\>li\]\:before\:content-\[\'\'\]>li::before{
	--tw-content:
		'';
	content:
		var(--tw-content);
}

.\[\&\>li\]\:last\:border-0:last-child>li{
	border-width:
		0px;
}

@media (min-width: 640px){
	.sm\:\[\&\>li\]\:w-\[48\%\]>li{
		width:
		48%;
	}
}

@media (min-width: 1024px){
	.lg\:\[\&\>li\]\:w-3\/10>li{
		width:
		30%;
	}
}

.\[\&\>ol\]\:list-inside>ol{
	list-style-position:
		inside;
}

.\[\&\>ol\]\:list-\[auto\]>ol{
	list-style-type:
		auto;
}

.\[\&\>ol\]\:list-decimal>ol{
	list-style-type:
		decimal;
}

.\[\&\>ul\]\:list-inside>ul{
	list-style-position:
		inside;
}

.\[\&\>ul\]\:list-disc>ul{
	list-style-type:
		disc;
}

